import React, { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Autocomplete } from '@mui/material'
import { Category } from 'src/contexts/types'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import useGenericContext from 'src/hooks/useGenericContext'

const PREFIX = 'AsyncSelectCategories'

const classes = {
  listbox: `${PREFIX}-listbox`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.listbox}`]: {
    display: 'grid',
    padding: '0px !important',
    gridGap: '20px',
    textAlign: 'center',
    paddingBottom: '18px !important',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fill, 150px)',
  },
}))

interface AsyncSelectCategoriesProps {
  selected?: Category
  onChange: (e, value: Category) => void
  inputRef?: React.MutableRefObject<HTMLElement | null>
  previouslySelected?: string[]
  openWhenNotSelected?: boolean
  error?: boolean | undefined
  label?: string | undefined
}

const AsyncSelectCategories: FC<AsyncSelectCategoriesProps> = ({ selected, onChange, inputRef, previouslySelected, openWhenNotSelected, error, label }) => {
  const filterStringRef = useRef<HTMLElement | null>(null)

  const {searchCategories} = useGenericContext()
  const [searchedCategories, setSearchedCategories] = React.useState<Category[]>([])
  const [searchString, setSearchString] = React.useState<string>('')
  const [searching, setSearching] = React.useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [offset, setOffset] = useState(0)
  const limit = 10

  useEffect(() => {
    if ((searchedCategories.length > 0) && !selected) {
      // NOTE: delay needed because there is no batching of hook dependencies so
      //       'items' come in while 'selected' is undefined, then 'selected' comes in
      let timerId
      if (openWhenNotSelected)
        timerId = setTimeout(() => setOpen(true), 200)

      return () => {
        clearTimeout(timerId)
      }
    }
  }, [searchedCategories, selected, openWhenNotSelected])

  const handleLoadMore = async (reset?: boolean) => {
    const newOffset = reset ? 0 : offset

    if (newOffset === -1) return
    if (searching) return

    setSearching(true)

    try {
      const chunk = (await searchCategories(searchString, limit, newOffset, previouslySelected)) || []
      setSearchedCategories(newOffset === 0 ? chunk : [...searchedCategories, ...chunk])
      setOffset(chunk.length < limit ? -1 : newOffset + limit)
    }
    catch (e) {
      setSearchedCategories([])
    }
    setSearching(false)
  }

  useEffect(() => {
    const timeout = setTimeout(() => handleLoadMore(true), 200)
    return () => clearTimeout(timeout)
  }, [searchString])

  useEffect(() => {
    if (open) setTimeout(() => filterStringRef?.current?.focus(), 0)
  }, [open, filterStringRef])

  const close = () => {
    setOffset(0)
    setSearchedCategories([])
    setOpen(false)
    // NOTE: setTimeout otherwise the blur doesn't work...
    setTimeout(() => filterStringRef.current?.blur(), 0)
  }

  const thumbnailUrl = selected?.featuredImage[0]?.smallUrl || ''

  return (
    <Autocomplete
      id='search-categories'
      open={open}
      onOpen={() => handleLoadMore(true)}
      onClose={close}
      clearIcon={searchString ? undefined : <span />}
      getOptionLabel={() => searchString}
      classes={{listbox: classes.listbox}}
      noOptionsText={searching ? 'Loading...' : 'No results'}
      isOptionEqualToValue={() => true} // remove annoying mui warning about options not matching
      options={searchedCategories}
      inputValue={searchString}
      onInputChange={(e, value) => {
        setSearchString(value)
      }}
      value={selected || null} // null prevents annoying mui warning about controlled component
      onChange={(e, value) => {
        if (value)
          onChange(e, value)
        close()
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listbox = event.currentTarget
          if (listbox.scrollTop + listbox.clientHeight < listbox.scrollHeight - 180)
            return
          handleLoadMore()
        },
        style: {
          textAlign: 'center',
          display: 'grid',
          gridGap: '24px',
          justifyContent: 'center',
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
          padding: '0px !important',
          paddingBottom: '18px !important',
        },
      }}
      renderOption={(props, category) => {
        return (
          <li {...props}
            key={category.id}
            style={{
              justifyContent: 'center',
            }}
          >
            <span style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pointerEvents: 'none',
              height: '100%',
              padding: '3px',
              zIndex: 100,
            }}
            >
              <img
                style={{
                  objectFit: 'cover',
                  width: '100px',
                  height: '100px',
                }}
                loading='lazy'
                src={category.featuredImage[0]?.smallUrl}
                alt={category.featuredImage[0]?.altText || category.title}
              />
              <Box
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  display: '-webkit-box',
                }}
              >
                <Typography variant='h5'
                  style={{
                    padding: '6px 0px',
                  }}
                >
                  {category.title}
                </Typography>
              </Box>
              {/* <Box
          style={{
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            marginTop: 'auto',
            overflow: 'hidden',
            display: '-webkit-box',
          }}
        >
          {stripHtml(category.description || '')}
        </Box> */}
            </span>
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            onBlur={close}
            onFocus={() => {
              setTimeout(() => setOpen(true), 200)
              handleLoadMore(true)
            }}
            inputRef={(element) => {
              filterStringRef.current = element
              if (inputRef)
                inputRef.current = element
            }}
            fullWidth={true}
            // NOTE: not necessary because it's handled by the Autocomplete but react still throws a warning about an uncontrolled component
            value={searchString}
            label={label || 'Select Category'}
            error={error}
            size='small' margin='dense' type='string' variant='outlined'
            inputProps={{
              ...params.inputProps,
              value: filterStringRef.current === document.activeElement ? searchString : selected?.title || searchString,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: thumbnailUrl ? <img
                style={{
                  marginLeft: '5px',
                  marginRight: '5px',
                  opacity: selected && !open ? 1 : 0,
                }}
                loading='lazy'
                width={20}
                height={20}
                src={thumbnailUrl}
              /> : null,
              endAdornment: (
                <Root>
                  {searching ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Root>
              ),
            }}
            InputLabelProps={params.InputLabelProps}
          />
        )
      }}
    />
  )
}

export default AsyncSelectCategories
