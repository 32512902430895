import { render, screen } from '@testing-library/react'

import wrapComponent from 'src/tests/wrapComponent'
import Storefronts from './index'

jest.mock('launchdarkly-react-client-sdk', () => ({
  useFlags: () => { return { campaign_stores: true }},
}))

const setup = () => render(wrapComponent(Storefronts, {}))

describe('Storefronts list page', () => {
  beforeEach(setup)

  it('renders table of storefonts', async () => {
    await screen.findByText('Active Campaign Stores')
    await screen.findByText('dasad')
    await screen.findByText('Select Campaign Status')
    expect(screen.getAllByRole('button', { name: 'UTM Links'}).length).toEqual(8)
    expect(screen.getAllByRole('button', { name: 'Edit'}).length).toEqual(8)
    expect(screen.getAllByRole('button', { name: 'Analytics'}).length).toEqual(7)
    expect(screen.getAllByRole('button', { name: 'Unpublish'}).length).toEqual(7)
    expect(screen.getAllByText('Single Product').length).toEqual(7)
    expect(screen.getAllByText('Campaign Home').length).toEqual(1)
    screen.getAllByRole('row', { name: 'Campaign Store Name Public URL Start Finish Last Modified Layout'})
    screen.getAllByRole('row', { name: new RegExp('dasad Visit Visit localhost')})
    screen.getAllByRole('row', { name: new RegExp('69b33a50-623d-4c73-9215-5d89b90a1931 Visit Visit localhost')})
    screen.getAllByRole('row', { name: new RegExp('SFOM Dresses Visit Visit localhost')})
    screen.getAllByRole('row', { name: new RegExp('Galaxy Earrings Promo Visit Visit localhost')})
    screen.getAllByRole('row', { name: new RegExp('Hauyin flowers Visit Visit localhost')})
  })
})
