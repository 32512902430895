import React, { FC} from 'react'
import { Box, SelectChangeEvent } from '@mui/material'
import { stripHtml } from 'src/utils/helpers'
import { Post } from 'src/contexts/types'
import { PostStats, PostDate } from '../PostStats'
import { SocialSelector, SocialSelectorProps } from './SocialSelector'
import { SocialSearchOption, SocialSearchValue } from 'src/views/CreateStorefront/components/SocialPicker'


type PostSelectorProps = {
  onRenderNext: () => void

  onSelect: (item: Post) => void
  selected: Post | null | undefined
  items: Post[]

  error?: boolean

  nameRef?: React.MutableRefObject<HTMLElement | null>
  nameErrorString?: string
  inputRef?: React.MutableRefObject<HTMLElement | null>
  label?: string

  campaignName: string
  onCampaignNameChange: (name: string) => void
  campaignDescription: string
  onCampaignDescriptionChange: (name: string) => void
  defaultOpen?: boolean
  handleSearchChange: (search: string) => void
  searchString: string
  handleSearchTypeChange: (event: SelectChangeEvent<SocialSearchValue>) => void
  searchType: SocialSearchValue
  searchTypeOptions: SocialSearchOption[]
  keepOpen?: boolean
}

const PostSelector: FC<PostSelectorProps> = ({
  onRenderNext,
  onSelect,
  selected,
  items,
  error,
  nameRef,
  nameErrorString,
  inputRef,
  label,
  campaignName,
  onCampaignNameChange,
  campaignDescription,
  onCampaignDescriptionChange,
  defaultOpen = true,
  handleSearchChange,
  searchString,
  handleSearchTypeChange,
  searchType,
  searchTypeOptions,
  keepOpen,
}) => {

  const renderPost = (item: Post) => (
    <>
      <Box sx={{
        position: 'relative',
      }}>
        <Box sx={{
          position: 'absolute',
          right: 0,
          m: '10px',
          p: '2px 5px',
          backgroundColor: '#F2F4F7',
        }}>
          {item.mediaType}
        </Box>
        <img
          style={{
            objectFit: 'contain',
            width: '150px',
            height: '150px',
          }}
          loading='lazy'
          src={item.thumbnail}
          alt=''
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          margin: '10px 0px',
        }}
      >
        <PostStats comments={item.commentCount} likes={item.likeCount} />
      </Box>
      <Box
        sx={{
          color: 'black',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          marginTop: 'auto',
          overflow: 'hidden',
          display: '-webkit-box',
        }}
      >
        {item?.caption && stripHtml(item.caption)}
      </Box>
      <Box>
        <PostDate timestamp={item.timestamp} />
      </Box>
    </>
  )

  const socialSelectorProps: SocialSelectorProps<Post> = {
    renderItem: renderPost,

    onRenderNext,
    onSelect,
    error,
    inputRef,
    label,
    items,
    selected:selected,
    nameRef,
    nameErrorString,
    campaignName,
    onCampaignNameChange,
    campaignDescription,
    onCampaignDescriptionChange,
    defaultOpen,
    handleSearchChange,
    searchString,
    handleSearchTypeChange,
    searchType,
    searchTypeOptions,
    keepOpen,
  }

  return (
    <Box>
      <SocialSelector {...socialSelectorProps} />
    </Box>
  )
}

export default PostSelector
