import React from 'react'
import { Box, TextField, Typography } from '@mui/material'
import SdxColorPicker from '../Components/SdxColorPicker'
import { useTranslation } from 'react-i18next'

interface ButtonSettingsProps {
  title?: string
  settingsKey: 'addToCart' | 'buyNow'
  formDetails: any
  setFormDetails: React.Dispatch<React.SetStateAction<any>>
  classes: any
}

const ButtonSettings: React.FC<ButtonSettingsProps> = ({ title, settingsKey, formDetails, setFormDetails, classes }) => {
  const { t } = useTranslation()

  const handleDetailsChange = (event: any) => {
    const { name, value } = event.target
    setFormDetails({ ...formDetails, [settingsKey]: { ...formDetails[settingsKey], [name]: value } })
  }

  return (
    <>
      {title && <Typography variant="h5" color="textPrimary" sx={{ pb: 3 }}>
        {title}
      </Typography>}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '600px',
          width: { xs: '100%', md: '75%' },
        }}
      >
        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '6px',
          }}
        >
          <Box
            sx={{
              minWidth: '200px',
              flexBasis: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <TextField
              id={`${settingsKey}.text`}
              name="text"
              type="text"
              variant="outlined"
              size="small"
              label="Button text"
              sx={{flex: 2, mb: 2}}
              onChange={handleDetailsChange}
              value={formDetails[settingsKey].text}
            />
          </Box>

          <TextField
            size='small' variant='outlined'
            id={`${settingsKey}.outlineWidthPixels`}
            name='outlineWidthPixels'
            sx={{flex: 1, mb: 2}}
            fullWidth

            label='Outline width'
            type='Number' // NOTE: Number instead of number... otherwise it leaves leading zeros
            value={formDetails[settingsKey].outlineWidthPixels || 0}
            onChange={e => {
              const ev = {
                target: {
                  name: e.target.name,
                  value: parseInt(e.target.value) || 0,
                },
              }
              handleDetailsChange(ev)
            }}
            InputProps={{endAdornment: <Box>px</Box>}}
          />
        </Box>

        <TextField
          id={`${settingsKey}.fontUrl`}
          name="fontUrl"
          type="text"
          variant="outlined"
          size="small"
          label="Font URL (Accepted Formats: .woff, .woff2, .ttf, .otf)"
          sx={{ flex: 2, mb: 2 }}
          onChange={handleDetailsChange}
          value={formDetails[settingsKey].fontUrl}
        />

        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '6px',
          }}
        >
          <Box
            sx={{
              minWidth: '200px',
              flexBasis: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <SdxColorPicker
              sx={{ flex: 1 }}
              label={t('Text color')}
              size="small"
              value={formDetails[settingsKey].textColor}
              onChange={(v) => {
                setFormDetails({ ...formDetails, [settingsKey]: { ...formDetails[settingsKey], textColor: v } })
              }}
            />
          </Box>

          <Box
            sx={{
              minWidth: '200px',
              flexBasis: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              mb: 3,
            }}
          >
            <SdxColorPicker
              sx={{ flex: 1 }}
              label={t('Text hover color')}
              size="small"
              value={formDetails[settingsKey].textHoverColor}
              onChange={(v) => {
                setFormDetails({ ...formDetails, [settingsKey]: { ...formDetails[settingsKey], textHoverColor: v } })
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '6px',
          }}
        >
          <Box
            sx={{
              minWidth: '200px',
              flexBasis: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <SdxColorPicker
              sx={{ flex: 1 }}
              label={t('Outline color')}
              size="small"
              value={formDetails[settingsKey].outlineColor}
              onChange={(v) => {
                setFormDetails({ ...formDetails, [settingsKey]: { ...formDetails[settingsKey], outlineColor: v } })
              }}
            />
          </Box>

          <Box
            sx={{
              minWidth: '200px',
              flexBasis: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              mb: 3,
            }}
          >
            <SdxColorPicker
              sx={{ flex: 1 }}
              label={t('Outline hover color')}
              size="small"
              value={formDetails[settingsKey].outlineHoverColor}
              onChange={(v) => {
                setFormDetails({ ...formDetails, [settingsKey]: { ...formDetails[settingsKey], outlineHoverColor: v } })
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '6px',
          }}
        >
          <Box
            sx={{
              minWidth: '200px',
              flexBasis: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <SdxColorPicker
              sx={{ flex: 1 }}
              label={t('Background color')}
              size="small"
              value={formDetails[settingsKey].backgroundColor}
              onChange={(v) => {
                setFormDetails({ ...formDetails, [settingsKey]: { ...formDetails[settingsKey], backgroundColor: v } })
              }}
            />
          </Box>

          <Box
            sx={{
              minWidth: '200px',
              flexBasis: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              mb: 3,
            }}
          >
            <SdxColorPicker
              sx={{ flex: 1 }}
              label={t('Background hover color')}
              size="small"
              value={formDetails[settingsKey].backgroundHoverColor}
              onChange={(v) => {
                setFormDetails({ ...formDetails, [settingsKey]: { ...formDetails[settingsKey], backgroundHoverColor: v } })
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ButtonSettings
