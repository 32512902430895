import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { ReactComponent as EditIcon } from 'src/assets/icons/Edit.svg'
import { ReactComponent as AnalyticsIcon } from 'src/assets/icons/Analytics.svg'
import { ReactComponent as UnpublishIcon } from 'src/assets/icons/Unpublish.svg'
import { ReactComponent as LinkIcon } from 'src/assets/icons/Link.svg'
import { ReactComponent as PublishIcon } from 'src/assets/icons/Publish.svg'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import type { FC }from 'react'
import { Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link, FormControlLabel, useTheme, Box, IconButton, Tooltip, MenuItem, Select,SelectChangeEvent } from '@mui/material'
import { ReactComponent as StartImg } from 'src/assets/icons/StartImg.svg'
import { ReactComponent as PlaceholderIcon } from 'src/assets/icons/IntegrationsIcon.svg'

import useGenericContext from 'src/hooks/useGenericContext'
import { RedirectModal } from './RedirectModal'
import { PageConfig, PageStatus } from 'src/types/interpretor'
import { LinkBuilderModal } from './components/LinkBuilderModal'
import { getRoutePath } from 'src/routes'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'
import LoadingBar from 'src/components/LoadingBar/LoadingBar'
import { getLocalhostPageUrl, isLocalhost } from 'src/utils/helpers'
import { InlineSocialPicker } from './components/InlineSocialPicker'
import { Ad } from 'src/contexts/types'
import { Post } from 'src/contexts/types'
import { OnPostOrAdSelectedProps } from '../CreateStorefront/components/SocialPicker'

const PREFIX = 'PageConfigs'

const classes = {
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
  marginRound: `${PREFIX}-marginRound`,
  marginRoundText: `${PREFIX}-marginRoundText`,
  table: `${PREFIX}-table`,
}

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.paper}`]: {
    margin: theme.spacing(4),
    padding: theme.spacing(8),
    textAlign: 'center',
    flexGrow: 1,
  },

  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'unset',
    margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },

  [`& .${classes.marginRound}`]: {
    margin: theme.spacing(3),
  },

  [`& .${classes.marginRoundText}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    width: 'unset',
    margin: theme.spacing(4),
    marginTop: '0px',
  },
}))

const pageStatusToLabelMap = new Map<PageStatus, string>([
  [PageStatus.Active, 'Active'],
  [PageStatus.Scheduled, 'Scheduled'],
  [PageStatus.Completed, 'Completed'],

  // TODO: allow displaying drafts in the dropdown when we support the status
  // https://ur2inc.atlassian.net/browse/DEV-3112
  //[PageStatus.Draft, 'Draft'],
])

const PageConfigs: FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { statusBasedPageConfigs, setStatusBasedPageConfigs, fetchStatusBasedPageConfigs,
    publishPageConfig, pageStatusQuery, setPageStatusQuery, loadingPages, loadingMore, utmLinkBuilderPageId,
    setUtmLinkBuilderPageId, duplicateStorefront, searchProducts,
    searchCategories } = useGenericContext()
  const [redirectModalOpen, setRedirectModalOpen] = useState(false)
  const [linkBuilderModalOpen, setLinkBuilderModalOpen] = useState(false)
  const [activePageConfig, setActivePageConfig] = useState({} as PageConfig)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const showUnpublished = pageStatusQuery !== PageStatus.Active
  const [loadingInlinePicker, setLoadingInlinePicker] = useState<boolean>(false)

  useEffect(() => {
    setLoadMore(false)
  }, [statusBasedPageConfigs])

  useEffect(() => {
    const cfg = statusBasedPageConfigs.find((pageConfig) => pageConfig.id === utmLinkBuilderPageId)
    if (!cfg) {
      setLinkBuilderModalOpen(false)
      return
    }
    setActivePageConfig(cfg)
    setTimeout(() => {
      setLinkBuilderModalOpen(true)
    }, 100)
  }, [utmLinkBuilderPageId, setLinkBuilderModalOpen, setActivePageConfig, statusBasedPageConfigs])

  // TODO: this is actually a last step in redirect change and a status toggle, needs better name
  const onSaveRedirectModal = async (cfg: PageConfig) => {
    setRedirectModalOpen(false)
    await publishPageConfig(cfg)
    // status was toggled, remove from status based configs
    setStatusBasedPageConfigs(statusBasedPageConfigs.filter(c => c.id !== cfg.id))
  }

  if (loadingPages) {
    return <SdxLoading />
  }

  const handleDuplicateStorefront = async (storefrontId) => {
    const storefront = statusBasedPageConfigs.find((pageConfig) => pageConfig.id === storefrontId)
    if (!storefront) { console.log('noop'); return }

    const confirmed = window.confirm(`Are you sure you want to Duplicate "${storefront.internalName}" Campaign Store?`)
    if (!confirmed) return

    const response = await duplicateStorefront(storefrontId)
    const duplicateSorefrontId = response?.data?.id
    if (!duplicateSorefrontId) {
      window.alert('Failed to duplicate storefront')
      return
    }

    handleEditStorefront(duplicateSorefrontId)
  }
  const handleEditStorefront = (storefrontId) => {
    history.push(getRoutePath('store_edit', { id: storefrontId }))
  }

  const handleViewStorefrontAnalytics = (storefrontId) => {
    history.push(getRoutePath('store_analytics', { id: storefrontId }))
  }

  const UnpublishIconWithToolTip = () =>
    <Tooltip title="Unpublish" placement="top">
      <UnpublishIcon role="img" aria-label="Unpublish" style={{ width: 20, height: 20 }} />
    </Tooltip>


  const PublishIconWithToolTip = () =>
    <Tooltip title="Publish" placement="top">
      <PublishIcon role="img" aria-label="Publish" style={{ width: 20, height: 20 }} />
    </Tooltip>

  const handleOnScroll = (ev) => {
    const scrollableHeight = ev.target.scrollHeight - ev.target.offsetHeight
    if (ev.target.scrollTop >= scrollableHeight - 200 && !loadingMore) {
      setLoadMore(true)
      fetchStatusBasedPageConfigs(undefined, true)
    }
  }

  const handleQueryOptionChange = (event: SelectChangeEvent<PageStatus>) => {
    const value = event.target.value as PageStatus // Cast value to PageStatus
    if (Object.values(PageStatus).includes(value)) {
      setPageStatusQuery(value)
    } else {
      console.error(`Invalid page status value: ${value}`)
    }
  }


  const handlePostOrAdSelectedFromInlinePicker = async ({postOrAd, integrationIndex}: OnPostOrAdSelectedProps) => {
    setLoadingInlinePicker(true)
    // This is an ad. llmAdDetails is only generated for ads by backend
    const postOrAdId = postOrAd.externalId || ''

    // TODO: why is llmAdDetails missing
    if ('llmAdDetails' in postOrAd) {
      const llmGuess = postOrAd.llmAdDetails.outcome

      if (llmGuess === 'products') {
        const suggestedProduct = postOrAd.llmAdDetails.products[0] || ''
        const productId = (await searchProducts(suggestedProduct, 1))?.[0]?.productId || ''
        history.push(`${getRoutePath('store_create')}?integrationIndex=${integrationIndex}&adId=${postOrAdId}&productId=${productId}&productSearch=${suggestedProduct}`)
      }
      else if (llmGuess === 'collections') {
        // TODO: I don't think we should introduce 'collection' terminology here, but leaving it for now since it might conflict with existing categoryId param
        const collectionSearch = postOrAd.llmAdDetails.collections[0] || ''
        const collectionId = (await searchCategories(collectionSearch, 1))?.[0]?.id || ''
        history.push(`${getRoutePath('store_create')}?integrationIndex=${integrationIndex}&adId=${postOrAdId}&collectionId=${collectionId}&collectionSearch=${collectionSearch}`)
      }
      else {
        history.push(`${getRoutePath('store_create')}?integrationIndex=${integrationIndex}&adId=${postOrAdId}`)
      }
    }
    else {
      // This is an post, but no llmAdDetails is generated for posts by backend
      history.push(`${getRoutePath('store_create')}?integrationIndex=${integrationIndex}&postId=${postOrAdId}`)
    }
    setLoadingInlinePicker(false)
  }

  return (
    <Root>
      {/* <TabbedHeader
        title='PageConfigs'
        rightSide={
          <Button onClick={() => history.push(getRoutePath('store_create'))} variant='contained' color="primary">New Campaign Store</Button>
        }>
        <Fragment>
          <Tabs
            TabIndicatorProps={{children: <span/>}}
            classes={{
              indicator: 'indicator',
              flexContainer: 'flexContainer',
            }}
            value={0}
            indicatorColor="primary"
          >
            {<Tab label='List' />}
          </Tabs>
        </Fragment>
      </TabbedHeader> */}

      <RedirectModal
        isOpen={redirectModalOpen}
        onSave={onSaveRedirectModal}
        onClose={() => setRedirectModalOpen(false)}
        pageConfig={activePageConfig}
      />

      <LinkBuilderModal
        key={activePageConfig.id + linkBuilderModalOpen}
        isOpen={linkBuilderModalOpen}
        onSave={(cfg: PageConfig) => {
          return Promise.resolve()
        }}
        onClose={() => {
          setUtmLinkBuilderPageId('')
          setLinkBuilderModalOpen(false)
        }}
        pageConfig={activePageConfig}
      />

      <div className={classes.header}>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 'large',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          {`${pageStatusToLabelMap.get(pageStatusQuery)} `}
          Campaign Stores
        </Typography>
        <div>
          <FormControlLabel
            style={{
              marginRight: 10,
            }}
            label={<Typography style={{
              marginBottom: '4px',
              color: showUnpublished ? theme.palette.brand.urIndigo : '',
              marginRight: `4px`,
            }}>
              Select Campaign Status
            </Typography>}
            labelPlacement='start'
            control={
              <Select
                value={pageStatusQuery}
                onChange={handleQueryOptionChange}
                sx={{
                  minWidth: 70,
                  '& .MuiSelect-select': { padding: '0 8px' },
                }}
              >
                {Object.values(PageStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {pageStatusToLabelMap.get(status)}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        </div>
      </div>

      <InlineSocialPicker
        loading={loadingInlinePicker}
        onPostOrAdSelected={handlePostOrAdSelectedFromInlinePicker}
        manualTabOnClick={() => history.push(`${getRoutePath('store_create')}?integrationIndex=-1`)}
      />

      {statusBasedPageConfigs?.length > 0 ?
        <TableContainer className={classes.table} component={Paper} onScroll={handleOnScroll} sx={{
          height: 'calc(100vh - 90px)',
          overflow: 'auto',
          marginBottom: '0!important',
        }}>
          <Table aria-label="storefront table">
            <TableHead sx={{
              position: 'sticky',
              top: 0,
              background: 'white',
              zIndex: 1,
              '&:after': {
                content: '""',
                position: 'absolute',
                top: 'calc(100% - 1px)',
                left: 0,
                zIndex: 2,
                width: '100%',
                height: '1px',
                backgroundColor: 'rgba(224, 224, 224, 1)',
              },
            }}>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Campaign Store Name</TableCell>
                <TableCell align="center">Public URL</TableCell>
                <TableCell align="center">Start</TableCell>
                <TableCell align="center">Finish</TableCell>
                <TableCell align="center">Last Modified</TableCell>
                <TableCell align="center">Layout</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {statusBasedPageConfigs
                .map((pageConfig, i) => (
                  <TableRow
                    key={pageConfig.pageUrl + i}
                  >
                    <TableCell align="left">
                      <Box sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '5px',
                        backgroundColor: pageConfig.campaignMedias[0]?.smallUrl === '' ? '' : '#E8E8E8',
                      }}>
                        {pageConfig.campaignMedias[0]?.smallUrl === '' ? <PlaceholderIcon style={{
                          width: '100%',
                          height: '100%',
                        }} /> :
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                              borderRadius: '5px',
                            }}
                            src={pageConfig.campaignMedias[0]?.smallUrl}
                            alt=''
                          />
                        }
                      </Box>
                    </TableCell>
                    <TableCell align="left">{pageConfig.internalName}</TableCell>
                    {/* TODO: rename backend to pageConfig */}
                    <TableCell align="center">
                      <Link target='_blank' href={pageConfig.pageUrl} rel="noreferrer">Visit</Link>
                      {!isLocalhost ? '' : <><br /><Link target='_blank' href={getLocalhostPageUrl(pageConfig.pageUrl)} rel="noreferrer">Visit localhost</Link></>}
                    </TableCell>
                    <TableCell align="center">{pageConfig.startDate === undefined ? '' : new Date(Number(pageConfig.startDate)).toLocaleString()}</TableCell>
                    <TableCell align="center">{pageConfig.endDate === undefined ? '' : new Date(Number(pageConfig.endDate)).toLocaleString()}</TableCell>
                    <TableCell align="center">{new Date(Number(pageConfig.updatedAt)).toLocaleString()}</TableCell>
                    <TableCell align="center">{
                      // TODO: solve with types from interpreter
                      pageConfig.layout === 'SINGLE_PRODUCT' ? 'Single Product' :
                        pageConfig.layout === 'SOCIAL_HOME' ? 'Campaign Home' :
                          pageConfig.layout === 'CATEGORY' ? 'Category' :
                            pageConfig.layout
                    }</TableCell>
                    <TableCell align="right" sx={{ width: '200px', '& svg path': { fill: theme.palette.brand.urIndigo } }}>

                      <IconButton
                        color="secondary"
                        sx={{ padding: '4px', width: 30, minWidth: 0 }}
                        onClick={() => {
                          setActivePageConfig(pageConfig)
                          setTimeout(() => {
                            setLinkBuilderModalOpen(true)
                          }, 100)
                        }}
                        disabled={pageConfig.isLegacy}
                      >
                        <Tooltip title="UTM Links" placement="top">
                          <LinkIcon role="img" aria-label="UTM Links" style={{ width: 20, height: 20 }} />
                        </Tooltip>
                      </IconButton>


                      <IconButton
                        color="secondary"
                        sx={{ padding: '4px', width: 30, minWidth: 0 }}
                        onClick={() => handleEditStorefront(pageConfig.id)}
                        disabled={pageConfig.isLegacy}
                      ><Tooltip title="Edit" placement="top"><EditIcon role="img" aria-label="Edit" style={{ width: 20, height: 20 }} />
                        </Tooltip>
                      </IconButton>


                      {pageConfig.layout !== 'SOCIAL_HOME' &&
                      <>
                        <IconButton
                          color="secondary"
                          sx={{ padding: '4px', width: 30, minWidth: 0 }}
                          onClick={() => handleViewStorefrontAnalytics(pageConfig.id)}
                        >
                          <Tooltip title="Analytics" placement="top">
                            <AnalyticsIcon role="img" aria-label="Analytics" style={{ width: 20, height: 20 }} />
                          </Tooltip>
                        </IconButton>

                        <IconButton
                          color="secondary"
                          sx={{ padding: '4px', width: 30, minWidth: 0 }}
                          onClick={async () => {
                            setActivePageConfig(pageConfig)
                            setRedirectModalOpen(true)
                          }}
                          disabled={pageConfig.isLegacy === true && pageConfig.status !== PageStatus.Active}
                        >
                          {pageConfig.status === PageStatus.Active ?
                            <UnpublishIconWithToolTip/>: showUnpublished ? <PublishIconWithToolTip/> : <UnpublishIconWithToolTip/>}
                        </IconButton>

                        <IconButton
                          color='secondary'
                          sx={{ padding: '4px', width: 30, minWidth: 0 }}
                          onClick={() => handleDuplicateStorefront(pageConfig.id)}
                          disabled={pageConfig.isLegacy}
                        >
                          <Tooltip title='Duplicate' placement='top'>
                            <ContentCopyOutlinedIcon />
                          </Tooltip>
                        </IconButton>
                      </>
                      }

                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {loadMore &&
            <Box sx={{
              width: '100%',
              height: 113,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}><LoadingBar /></Box>
          }
        </TableContainer>

        : <Paper className={classes.paper}>
          <StartImg className={classes.marginRound} />
          <div className={classes.marginRoundText}>
            <Typography variant='h1'>{showUnpublished? `There are no ${pageStatusToLabelMap.get(pageStatusQuery)} campaign stores`: 'Start increasing your social conversion with'}</Typography>
            <Typography variant='h1'>{!showUnpublished ? 'campaign stores' : ''}</Typography>
          </div>
          <Typography>{showUnpublished ? 'All your campaign stores are currently published' : 'Create your first campaign store to use in a campaign'}</Typography>
        </Paper>}
    </Root>
  )
}

export default PageConfigs
