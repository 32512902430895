import { M } from 'msw/lib/glossary-de6278a9'
import  {parseSentences} from './helpers'

test('no match on basic sentence', () => {
  expect(parseSentences('This is a single sentence.')).toStrictEqual(['This is a single sentence.'])
})

test('split interrobangs 1', () => {
  expect(parseSentences('Did you see that!? Holy Moly!')).toStrictEqual(['Did you see that!?','Holy Moly!'])
})

test('split interrobangs 2', () => {
  expect(parseSentences('Did you see that?! Holy Moly!')).toStrictEqual(['Did you see that?!','Holy Moly!'])
})

test('do not split elipsis', () => {
  expect(parseSentences('Superior quality... Made in America.')).toStrictEqual(['Superior quality... Made in America.'])
})

test('split sequential exclamations', () => {
  expect(parseSentences('The hottest sale of the year!!! Come by today!')).toStrictEqual(['The hottest sale of the year!!!','Come by today!'])
})

test('split sequential question marks', () => {
  expect(parseSentences('Will you miss the hottest sale of the year??? Come by today!')).toStrictEqual(['Will you miss the hottest sale of the year???','Come by today!'])
})

test('split multiple sentences 1', () => {
  expect(parseSentences('Superior quality. Made in America.')).toStrictEqual(['Superior quality.', 'Made in America.'])
})

test('split multiple sentences 2', () => {
  expect(parseSentences('Is your bra uncomfortable? Try one of ours!')).toStrictEqual(['Is your bra uncomfortable?', 'Try one of ours!'])
})

test('split multiple sentences 3', () => {
  expect(parseSentences('Looking for a new swimsuit? Ours are the best! We promise.')).toStrictEqual(['Looking for a new swimsuit?', 'Ours are the best!','We promise.'])
})

test('abbreviations 1', () => {
  expect(parseSentences('Superior quality. Made in the U.S.A.')).toStrictEqual(['Superior quality.','Made in the U.S.A.'])
})

test('abbreviations 2', () => {
  expect(parseSentences('Dr. Martens classic boots.')).toStrictEqual(['Dr. Martens classic boots.'])
})

test('empty string', () => {
  expect(parseSentences('')).toStrictEqual([])
})
