import { PageConfig } from 'src/types/interpretor'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'

export const isComponentEditable = (component: ContainerConfig) =>
  isComponentCustom(component) ||
  component.componentType === 'CAMPAIGN_STORYBOOK' ||
  component.componentType === 'SIMILAR_PRODUCTS' ||
  component.componentType === 'ALTERNATES_IN_CATEGORY'

export const isComponentCustom = (component: ContainerConfig) => {
  if (isComponentIntegration(component)) return false
  if (isComponentHook(component)) return false
  if (isComponentHiddenEverywhere(component)) return false
  return true
}

export const isComponentHook = (component: ContainerConfig) => {
  if (component.componentType === 'SIMILAR_PRODUCTS') return true
  if (component.componentType === 'ALTERNATES_IN_CATEGORY') return true
  if (component.componentType === 'ALL_PUBLISHED_CAMPAIGNS') return true
  if (component.componentType === 'CAMPAIGN_STORYBOOK') return true
}

export const isComponentIntegration = (component: ContainerConfig) => {
  if (component.class === 'INTEGRATION') return true
}

export const isComponentHiddenOnLayout = (component: ContainerConfig, layout: PageConfig['layout']) => {
  if (isComponentHiddenEverywhere(component)) return true

  if (layout === 'CATEGORY' || layout === 'SOCIAL_HOME') {
    if (component.componentType === 'BACK_IN_STOCK_NOTIFICATION') return true
    if (component.componentType === 'SIMILAR_PRODUCTS') return true
    if (component.componentType === 'ALTERNATES_IN_CATEGORY') return true
    if (component.componentType === 'REVIEWS') return true
  }
}

export const isComponentHiddenEverywhere = (component: ContainerConfig) => {
  if (isComponentSingleton(component)) return true
}

export const isComponentSingleton = (component: ContainerConfig) => {
  if (component.componentType === 'CART') return true
  if (component.componentType === 'HEADER') return true
  if (component.componentType === 'FOOTER') return true
  if (component.componentType === 'ATTRIBUTION_SURVEY') return true
}

export const shouldDisplayInCustomLibraryTab = (component: ContainerConfig, pageConfig: PageConfig) => {
  return isComponentCustom(component)
}

export const shouldDisplayInHooksTab = (component: ContainerConfig, pageConfig: PageConfig) => {
  if (!isComponentHook(component)) return false
  if (isComponentHiddenOnLayout(component, pageConfig.layout)) return false

  if (component.componentType === 'CAMPAIGN_STORYBOOK') {
    // Only show the storybook with hero image if the page is a social home page.
    const storybookHasHeroImage = component.contentConfiguration.value.uiSettings['displayHero']
    if (storybookHasHeroImage) return pageConfig.layout === 'SOCIAL_HOME'
  }
  return true
}

export const shouldDisplayInIntegrationsTab = (component: ContainerConfig, pageConfig: PageConfig) => {
  if (!isComponentIntegration(component)) return false
  if (isComponentHiddenOnLayout(component, pageConfig.layout)) return false
  return true
}
