import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { List } from 'react-movable'
import { Container, Box, FormControl, InputLabel, Select, MenuItem, Button, Checkbox, FormControlLabel, Tooltip, useTheme  } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'

import { ReactComponent as HooksIcon } from 'src/assets/icons/Hooks.svg'
import { ReactComponent as IntegrationsIcon } from 'src/assets/icons/IntegrationsIcon.svg'
import { ReactComponent as LibraryIcon } from 'src/assets/icons/LibraryFilled.svg'

import type { FC } from 'react'

import Page from 'src/components/Page'
import ErrorPage from '../ErrorPage'
import useGenericContext from 'src/hooks/useGenericContext'
import { ComponentsDefaultPagesRequest, ComponentTypeResponse } from '../Components/types'
import { deepCloneJson } from 'src/utils/helpers'
import { isComponentHiddenOnLayout } from 'src/utils/classifyComponents'


const PREFIX = 'DefaultPagesSettings'

const classes = {
  main: `${PREFIX}-main`,
  title: `${PREFIX}-title`,
  subTitle: `${PREFIX}-subTitle`,
  info: `${PREFIX}-info`,
  listItem: `${PREFIX}-listItem`,
  inputLabel: `${PREFIX}-inputLabel`,
  removeBtn: `${PREFIX}-removeBtn`,
  saveBtn: `${PREFIX}-saveBtn`,
  componentName: `${PREFIX}-componentName`,
  class: `${PREFIX}-class`,
}

const ListItem = styled('div')(({theme}) => ({
  [`& .${classes.listItem}`]: {
    backgroundColor: '#F4F6F8',
    height: 56,
    width: '100%',
    padding: '0 21px',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.componentName}`]: {
    fontSize: '16px',
    color: '#37474F',
    fontWeight: 600,
    marginLeft: 26,
  },
  [`& .${classes.class} span`]: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 25,
    '& svg': {
      marginBottom: 3,
      marginRight: 5,
    },
  },
  [`& .${classes.removeBtn}`]: {
    marginLeft: '16px',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    border: 0,
    fontSize: 14,
    backgroundColor: 'transparent',
    color: theme.palette.brand.urIndigo,
    cursor: 'pointer',
    '& svg': {
      marginTop: 2,
      width: 16,
      marginLeft: 7,
    },
  },
}))

const StyledPage = styled(Page)(() => ({
  [`& .${classes.main}`]: {
    backgroundColor: 'white',
    marginTop: 40,
    marginBottom: 40,
    borderRadius: '4px',
    padding: '32px',
  },

  [`& .${classes.title}`]: {
    fontSize: '18px',
    display: 'inline-block',
    width: '100%',
    margin: 0,
    color: '#455A64',
    fontWeight: 600,
    borderBottom: '1px solid #ECEFF1',
    paddingBottom: '9px',
  },

  [`& .${classes.subTitle}`]: {
    fontSize: '16px',
    display: 'inline-block',
    width: '100%',
    margin: 0,
    color: '#455A64',
    fontWeight: 600,
    marginTop: 24,
    marginBottom: 16,
  },

  [`& .${classes.info}`]: {
    color: '#546D78',
    fontSize: '16px',
    marginTop: 15,
    marginBottom: 8,
  },

  [`& .${classes.inputLabel}`]: {
    fontSize: '13px',
    color: '#78909C',
    padding: '0 8px',
    top: '50%',
    transform: 'translate(0, -50%) scale(1)',
  },

  [`& .${classes.saveBtn}`]: {
    float: 'right',
  },
}))

const DefaultPagesSettings: FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    getAllDefaultPages,
    components,
    createDynamicPages,
  } = useGenericContext()

  const [pageError, setPageError] = useState(false)
  const [saveBtnBool, setSaveBtnBool] = useState<boolean>(true)
  const [updates, setUpdates] = useState(false)
  const [singleProductLayoutComponents, setSingleProductLayoutComponents] = useState<ComponentTypeResponse[]>([])
  const [categoryLayoutComponents, setCategoryLayoutComponents] = useState<ComponentTypeResponse[]>([])

  useEffect(() => {
    async function results() {
      const response = await getAllDefaultPages()
      if (response?.data && response?.data) {
        response.data.forEach(item => {
          item.components = item.components.map((component, index) => {
            component.mlOrdering = item.mlOrderingUsed && typeof item.mlOrderingUsed[index] !== 'undefined' ? item.mlOrderingUsed[index] : true
            return component
          })
          if (item.layout === 'CATEGORY') setCategoryLayoutComponents(deepCloneJson(item.components))
          if (item.layout === 'SINGLE_PRODUCT') setSingleProductLayoutComponents(deepCloneJson(item.components))
        })
      }
      setUpdates(true)
    }
    results()
  }, [])

  const pushData = (async () => {
    setSaveBtnBool(true) // TODO: solve with comparison with original

    const requestData: ComponentsDefaultPagesRequest[] = []

    requestData.push({
      layout: 'SINGLE_PRODUCT',
      ComponentIDS: singleProductLayoutComponents.map((component) => { return component?.componentId }),
      mlOrderingUsed: singleProductLayoutComponents.map(c => c.mlOrdering || false),
    })

    requestData.push({
      layout: 'CATEGORY',
      ComponentIDS: categoryLayoutComponents.map((component) => { return component?.componentId }),
      mlOrderingUsed: categoryLayoutComponents.map(c => c.mlOrdering || false),
    })

    const response = await createDynamicPages(requestData)
  })

  const addComponent = ((e, layout) => {
    setSaveBtnBool(false) // TODO: solve with comparison with original

    const componentId = e?.target?.value

    const component = deepCloneJson(components.find(x => x.componentId === componentId))
    if (!component) return console.warn(`No component found with id: ${componentId}`)

    component.mlOrdering = true

    if (layout === 'SINGLE_PRODUCT') setSingleProductLayoutComponents([...singleProductLayoutComponents, component])
    if (layout === 'CATEGORY') setCategoryLayoutComponents([...categoryLayoutComponents, component])
  })

  const changeMLOrdering = (async (ev, layout, item) => {
    setSaveBtnBool(false) // TODO: solve with comparison with original

    item.value.mlOrdering = ev.target.checked
    if (layout === 'SINGLE_PRODUCT') setSingleProductLayoutComponents([...singleProductLayoutComponents])
    if (layout === 'CATEGORY') setCategoryLayoutComponents([...categoryLayoutComponents])
  })

  const componentsListChange = (async (e, layout) => {
    setSaveBtnBool(false) // TODO: solve with comparison with original

    if (layout === 'SINGLE_PRODUCT') {
      if (e.oldIndex > e.newIndex) {
        singleProductLayoutComponents.splice(e.newIndex, 0, singleProductLayoutComponents[e.oldIndex])
        singleProductLayoutComponents.splice(e.oldIndex + 1, 1)
      } else {
        singleProductLayoutComponents.splice(e.newIndex + 1, 0, singleProductLayoutComponents[e.oldIndex])
        singleProductLayoutComponents.splice(e.oldIndex, 1)
      }
      await setSingleProductLayoutComponents([...singleProductLayoutComponents])
    } else if (layout === 'CATEGORY') {
      if (e.oldIndex > e.newIndex) {
        categoryLayoutComponents.splice(e.newIndex, 0, categoryLayoutComponents[e.oldIndex])
        categoryLayoutComponents.splice(e.oldIndex + 1, 1)
      } else {
        categoryLayoutComponents.splice(e.newIndex + 1, 0, categoryLayoutComponents[e.oldIndex])
        categoryLayoutComponents.splice(e.oldIndex, 1)
      }
      await setCategoryLayoutComponents([...categoryLayoutComponents])
    }
  })

  const removeComponent = (async (e, layout) => {
    setSaveBtnBool(false) // TODO: solve with comparison with original

    if (layout === 'SINGLE_PRODUCT') {
      singleProductLayoutComponents.splice(e.index, 1)
      await setSingleProductLayoutComponents([...singleProductLayoutComponents])
    } else if (layout === 'CATEGORY') {
      categoryLayoutComponents.splice(e.index, 1)
      await setCategoryLayoutComponents([...categoryLayoutComponents])
    }
  })

  if (pageError) {
    return <ErrorPage mainText="There is an error loading the page" subText="There was an error loading the page you are trying to view" />
  }

  return (
    <StyledPage title={t('default page.page settings')}>
      <Container fixed>
        <Box className={classes.main}>
          <h4 className={classes.title}>{t('default page.page settings')}
            <Button onClick={pushData} className={classes.saveBtn} color="secondary" variant="contained" type="submit" disabled={saveBtnBool}>
              {t('default page.save changes')}
            </Button>
          </h4>
          <p className={classes.info}>{t('default page.title')}</p>

          <Box>
            <h5 className={classes.subTitle}>Single Product Layout</h5>
            <FormControl style={{ height: 32, marginBottom: 23, position: 'relative' }}>
              <InputLabel className={classes.inputLabel} id="add-single-product-component-label">{t('default page.add a component...')}</InputLabel>
              <Select
                labelId="add-single-product-component-label"
                id="add-single-product-component"
                value=""
                variant={'outlined'}
                style={{
                  width: 350,
                  height: 32,
                }}
                onChange={(event) => addComponent(event, 'SINGLE_PRODUCT')}
              >
                {components
                  .filter(x => !isComponentHiddenOnLayout(x, 'SINGLE_PRODUCT'))
                  .filter(x => !singleProductLayoutComponents.find(y => x.componentId === y.componentId))
                  .map(item => <MenuItem key={item.componentId} value={item.componentId}>{item.internalName}</MenuItem>)}
              </Select>

            </FormControl>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <List
              values={singleProductLayoutComponents}
              onChange={(e) => componentsListChange(e, 'SINGLE_PRODUCT')}
              renderList={({ children, props }) => <div {...props}>{children}</div>}
              renderItem={(item) => {
                return (
                  <ListItem
                    {...item.props}
                  >
                    <div style={{ paddingBottom: 8 }}>
                      <div className={classes.listItem} style={{
                        cursor: item.isDragged ? 'grabbing' : 'grab',
                      }} data-movable-handle>
                        <div>
                          <DragIcon />
                        </div>
                        <h4 className={classes.componentName}>{item.value?.internalName}</h4>
                        <div style={{ marginLeft: 'auto' }} />
                        <div className={classes.class}>
                          {item.value?.class?.indexOf('HOOK') !== -1 && (
                            <span>
                              <HooksIcon style={{color: theme.palette.brand.urGreen}} /> Hooks
                            </span>
                          )}
                          {item.value?.class?.indexOf('INTEGRATION') !== -1 && (
                            <span>
                              <IntegrationsIcon style={{color: theme.palette.brand.urOrange}} /> Integrations
                            </span>
                          )}
                          {item.value?.class?.indexOf('SAVED') !== -1 && (
                            <span>
                              <LibraryIcon style={{color: theme.palette.brand.urIndigo}} /> Library
                            </span>
                          )}
                        </div>
                        <Tooltip title="Use AI ordering on component" placement="top">
                          <FormControlLabel control={<Checkbox
                            checked={!!item.value.mlOrdering}
                            onChange={(event) => changeMLOrdering(event, 'SINGLE_PRODUCT', item)}
                            inputProps={{ 'aria-label': 'uncontrolled' }}
                          />} label={t('user settings.AI on')} />
                        </Tooltip>
                        <button onClick={(e) => removeComponent(item, 'SINGLE_PRODUCT')} className={classes.removeBtn}>{t('default page.remove')}
                          <CloseIcon />
                        </button>
                      </div>
                    </div>
                  </ListItem>
                )
              }}
            >
            </List>
          </Box>

          <Box>
            <h5 className={classes.subTitle}>{t('default page.category layout')}</h5>
            <FormControl style={{ height: 32, marginBottom: 23 }}>
              <InputLabel className={classes.inputLabel} id="add-single-product-component-label">{t('default page.add a component...')}</InputLabel>
              <Select
                labelId="add-single-product-component-label"
                id="add-single-product-component"
                value=""
                variant={'outlined'}
                style={{
                  width: 350,
                  height: 32,
                }}
                onChange={(event) => addComponent(event, 'CATEGORY')}
              >
                {components
                  .filter(x => !isComponentHiddenOnLayout(x, 'CATEGORY'))
                  .filter(x => !categoryLayoutComponents.find(y => x.componentId === y.componentId))
                  .map(item => <MenuItem key={item.componentId} value={item.componentId}>{item.internalName}</MenuItem>)
                }
              </Select>

            </FormControl>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <List
              values={categoryLayoutComponents}
              onChange={(e) => componentsListChange(e, 'CATEGORY')}
              renderList={({ children, props }) => <div {...props}>{children}</div>}
              renderItem={(item) => {
                return (
                  <ListItem
                    {...item.props}
                  >
                    <div style={{ paddingBottom: 8 }}>
                      <div className={classes.listItem} style={{
                        cursor: item.isDragged ? 'grabbing' : 'grab',
                      }} data-movable-handle>
                        <div>
                          <DragIcon />
                        </div>
                        <h4 className={classes.componentName}>{item.value?.internalName}</h4>
                        <div style={{ marginLeft: 'auto' }} />
                        <div className={classes.class}>
                          {item.value?.class?.indexOf('HOOK') !== -1 && (
                            <span>
                              <HooksIcon style={{color: theme.palette.brand.urGreen}} /> Hooks
                            </span>
                          )}
                          {item.value?.class?.indexOf('INTEGRATION') !== -1 && (
                            <span>
                              <IntegrationsIcon style={{color: theme.palette.brand.urOrange}} /> Integrations
                            </span>
                          )}
                          {item.value?.class?.indexOf('SAVED') !== -1 && (
                            <span>
                              <LibraryIcon style={{color: theme.palette.brand.urIndigo}} /> Library
                            </span>
                          )}
                        </div>
                        <Tooltip title="Use AI ordering on component" placement="top">
                          <FormControlLabel control={<Checkbox
                            checked={!!item.value.mlOrdering}
                            onChange={(event) => changeMLOrdering(event, 'CATEGORY', item)}
                            inputProps={{ 'aria-label': 'uncontrolled' }}
                          />} label={t('user settings.AI on')} />
                        </Tooltip>
                        <button onClick={(e) => removeComponent(item, 'CATEGORY')} className={classes.removeBtn}>{t('default page.remove')}
                          <CloseIcon />
                        </button>
                      </div>
                    </div>
                  </ListItem>
                )
              }}
            >
            </List>
          </Box>

        </Box>
      </Container>
    </StyledPage>
  )
}

export default DefaultPagesSettings
