import {getActiveCustomDomainApi} from '../contexts/api/settingsApi'

export async function buildPreviewURL(
  shop: string,
  path: string,
  queryParams: string,
  isLocalhost: boolean,
): Promise<string> {
  const response = await getActiveCustomDomainApi(shop)

  // If there is no response for the custom domain, something is probably broken
  if (!response || !response.data.domain) {
    // TODO: this should probably error out or return an empty string
    return ''
  }

  // Select the domain based on whether we are on localhost
  const domain = isLocalhost ? 'http://localhost:3001' : `https://${response.data.domain}`
  const url = new URL(path, domain)

  // Add the `hostname` query parameter for localhost
  if (isLocalhost) {
    const hostnameQuery = response.data.domain
    const additionalQuery = `hostname=${hostnameQuery}`
    url.search = queryParams ? `${queryParams}&${additionalQuery}` : additionalQuery
  } else {
    url.search = queryParams
  }

  return url.toString()
}
