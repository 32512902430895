import React, { FC } from 'react'
import { Box, SelectChangeEvent} from '@mui/material'
import { Ad } from 'src/contexts/types'
import { SocialSearchOption, SocialSearchValue } from 'src/views/CreateStorefront/components/SocialPicker'
import {SocialSelector, SocialSelectorProps} from './SocialSelector'


type AdSelectorProps = {
  onRenderNext: () => void

  onSelect: (item: Ad) => void
  selected: Ad | null | undefined
  items: Ad[]

  error?: boolean

  nameRef?: React.MutableRefObject<HTMLElement | null>
  nameErrorString?: string
  inputRef?: React.MutableRefObject<HTMLElement | null>
  label?: string

  campaignName: string
  onCampaignNameChange: (name: string) => void
  campaignDescription: string
  onCampaignDescriptionChange: (name: string) => void
  defaultOpen?: boolean
  handleSearchChange: (search: string) => void
  searchString: string
  handleSearchTypeChange: (event: SelectChangeEvent<SocialSearchValue>) => void
  searchType: SocialSearchValue
  searchTypeOptions: SocialSearchOption[]
  keepOpen?: boolean
}

const AdSelector: FC<AdSelectorProps> = ({
  onRenderNext,
  onSelect,
  selected,
  items,
  error,
  nameRef,
  nameErrorString,
  inputRef,
  label,
  campaignName,
  onCampaignNameChange,
  campaignDescription,
  onCampaignDescriptionChange,
  defaultOpen = true,
  handleSearchChange,
  searchString,
  handleSearchTypeChange,
  searchType,
  searchTypeOptions,
  keepOpen,
}) => {

  const renderAd = (item: Ad) => (
    <>
      <Box sx={{
        position: 'relative',
      }}>
        <Box sx={{
          position: 'absolute',
          right: 0,
          m: '10px',
          p: '2px 5px',
          backgroundColor: '#F2F4F7',
        }}>
          {item.mediaType}
        </Box>
        <img
          style={{
            objectFit: 'contain',
            width: '150px',
            height: '150px',
          }}
          loading='lazy'
          alt=''
          src={item.thumbnail}
        />
      </Box>
      <Box sx={{ color: 'grey' }}>ID: {item.externalId}</Box>
      <Box sx={{ fontSize: 12, fontWeight: 'bold' }}>{item.campaignName}</Box>
      <Box>{item.adName}</Box>
      <Box>{item.message}</Box>
    </>
  )

  const socialSelectorProps: SocialSelectorProps<Ad> = {
    renderItem: renderAd,

    onRenderNext,
    onSelect,
    error,
    inputRef,
    label,
    items,
    selected,
    nameRef,
    nameErrorString,
    campaignName,
    onCampaignNameChange,
    campaignDescription,
    onCampaignDescriptionChange,
    defaultOpen,
    handleSearchChange,
    searchString,
    handleSearchTypeChange,
    searchType,
    searchTypeOptions,
    keepOpen,
  }

  return (
    <Box>
      <SocialSelector {...socialSelectorProps} />
    </Box>
  )
}

export default AdSelector
