import React, { FC } from 'react'


import { ReactComponent as SingleProductLayoutIcon } from 'src/assets/icons/SingleProductLayout.svg'
import { ReactComponent as CategoryLayoutIcon } from 'src/assets/icons/CategoryLayout.svg'
import { Box, useTheme } from '@mui/material'

type LayoutData = {
  icon: any
  layout: 'SINGLE_PRODUCT' | 'MULTI_PRODUCT' | 'CATEGORY' | 'SOCIAL_HOME'
  title: string
  text: string
  disabled?: boolean
}

const layoutDatas: LayoutData[] = [
  {layout: 'SINGLE_PRODUCT', icon: SingleProductLayoutIcon, title: 'Single Product', text: 'Promote single product from your store'},
  // {layout: 'multi_product', icon: MultiProductLayoutIcon, title: 'Multi Product', text: 'Promote multiple products from your store', disabled: true},
  {layout: 'CATEGORY', icon: CategoryLayoutIcon, title: 'Category', text: 'Promote products from within a specific category'},
  // {layout: 'SOCIAL_HOME', icon: SocialHomeLayoutIcon, title: 'Campaign Home', text: 'Create shoppable pages for your social media profiles'}, // User can no longer create a campaign home, one campaign homeis created automatically.
]

type Props = {
  layout: string
  onChange: (newLayout: string) => void
}
export const LayoutPicker: FC<Props> = ({layout, onChange}) => {
  const theme = useTheme()

  return (
    <Box>
      <Box sx={{display: 'flex', gap: '4px', py: 1}}>
        {
          layoutDatas.map((layoutData, i) => {
            const selected = layout === layoutData.layout
            return (
              <Box
                key={i}
                style={{
                  WebkitFilter: layoutData.disabled ? 'brightness(100%) grayscale(100%)': '',
                  border: `1px solid ${selected ? theme.palette.brand.frenchBlue : 'transparent'}`,
                  boxSizing: 'border-box',
                  borderRadius: '5px',
                  background: '#F4F6F8',
                }}
                onClick={() => {if (!layoutData.disabled) onChange(layoutData.layout)}}
              >
                <LayoutItem
                  Icon={layoutData.icon}
                  title={layoutData.title}
                  text={layoutData.text}
                />
              </Box>
            )
          })
        }
      </Box>
    </Box>
  )
}

type LayoutItemProps = {
  Icon: any
  title: string
  text: string
}
const LayoutItem: FC<LayoutItemProps> = ({
  Icon, title, text,
}) => {
  return (
    <Box
      style={{
        boxSizing: 'unset',
        padding: '8px',
        minHeight: '50px',
      }}
    >
      <Box style={{display: 'flex'}}>
        <Box style={{marginRight: '8px'}}>
          <Icon />
        </Box>
        <Box style={{}}>
          <Box style={{fontWeight: 'bold'}}>{title}</Box>
          <span style={{flex: 1}}>{text}</span>
        </Box>
      </Box>
    </Box>
  )
}
