import {History} from 'history'

export const nbsp = '\u00A0'

export type Point = {x: number, y: number}
export type Rect = {x: number, y: number, width: number, height: number}

export const getDeviceType = (): 'Tablet' | 'Mobile' | 'Desktop' => {
  const { userAgent } = navigator
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return 'Tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)
  ) {
    return 'Mobile'
  }
  return 'Desktop'
}

export const isLocalhost = window.location.hostname === 'localhost'

export const getLocalhostPageUrl = (pageUrl: string) => {
  const parsedUrl = new URL(pageUrl)
  const originalDomain = parsedUrl.hostname
  const pathAndQuery = parsedUrl.pathname + parsedUrl.search
  return `http://localhost:3001${pathAndQuery}&hostname=${originalDomain}`
}

export const deepCloneJson = <T>(x: T): T => JSON.parse(JSON.stringify(x))

export const roundToDecimals = (num: number, decimals = 2) => Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)

export const stripHtml = (html: string): string => html.replace(/<[^>]+>/g, '')

export const asyncTimeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

type DeepEqualValue = any
export const deepEqual = (obj1: DeepEqualValue, obj2: DeepEqualValue, ignoreKeys: string[] = []): boolean => {
  if (obj1 === obj2) return true
  // NOTE: typeof [] is 'object', typeof null is 'object'
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null)
    return false

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (ignoreKeys.includes(key)) continue

    if (!keys2.includes(key)) return false
    if (!deepEqual(obj1[key], obj2[key], ignoreKeys)) return false
  }

  return true
}

export const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
}

export const dateToMs = (date: string | number) => new Date(date).getTime()

export const validateUrl = (url: string) => (/^(https:\/\/[a-z0-9-.]+\.[^\s]+)/i).test(url)

export const removeProtocolFromUrl = (url: string) => {
  const regex = /(^\w+:|^)\/\//i

  while(regex.test(url))
    url = url.replace(regex, '')
  return url
}

export const processUrlInput = (url: string) => {
  url = url.replace(/\s/g, '')
  url = removeProtocolFromUrl(url)
  return 'https://' + url
}

// https://stackoverflow.com/questions/11935175/sampling-a-random-subset-from-an-array
export const getRandomSubarray = (arr: any[], size: number) => {
  const shuffled = arr.slice(0)
  let i = arr.length
  let temp
  let index
  while (i--) {
    index = Math.floor((i + 1) * Math.random())
    temp = shuffled[index]
    shuffled[index] = shuffled[i]
    shuffled[i] = temp
  }
  return shuffled.slice(0, size)
}

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const formatPercent = (num?: number, postfix = '%', defaultValue = '-') => {
  if (num === undefined) return defaultValue
  let str = (num * 100).toFixed(2)
  str = str.replaceAll('.00', '')
  return str + postfix
}

export const safeParseJSON = (str: any) => {
  let a
  try {
    a = JSON.parse(str)
  }
  catch (e) {
    return null
  }
  return a
}

export const upsert = <T>(arr: T[], item: T, key: keyof T) => {
  const newArr = arr.map(u => u[key] === item[key] ? item : u)
  if (!newArr.find(u => u[key] === item[key])) newArr.push(item)
  return newArr
}


export const parseSentences = (text: string): string[]  =>{
  // matches on a single instance of a punctuation mark if:
  //  its not preceded by up to 3 characters to account for abbreviations: dr., U.S.A., etc.
  //  its at the end of a word.
  const regex = /(?<!\b\w{1,3})([.!?])(?=\s+[A-Z]|$)/g
  const parts = text.split(regex)
  const sentences: string[] = []

  // Combine the text and punctuation so that punctuation marks aren't array elements.
  for (let i = 0; i < parts.length; i += 2) {
    const sentence = parts[i] + (parts[i + 1] || '')
    sentences.push(sentence.trim())
  }

  // trim the last element from the array if its an empty string.
  if (sentences[sentences.length - 1] === '') {
    sentences.pop()
  }

  // don't split sentences that end with elipses.
  for (let i = sentences.length - 1; i > 0; --i) {
    if (sentences[i - 1].endsWith('..')) {
      sentences[i - 1] += ' ' + sentences[i]
      sentences.splice(i, 1)
    }
  }

  return sentences
}

export const deleteSearchParams_sideEffect = (history: History, searchParams: URLSearchParams, paramsToDelete: string[]) => {
  let paramsFound = false
  paramsToDelete.forEach((p) => {
    if (searchParams.has(p)) {
      searchParams.delete(p)
      paramsFound = true
    }
  })
  paramsToDelete.forEach(p => searchParams.delete(p))
  history.replace({
    pathname: window.location.pathname,
    search: searchParams.toString(),
  })
}
