import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Resizable } from 'react-resizable'
import type { FC } from 'react'
import { Paper, Button, Box } from '@mui/material'

import { deepCloneJson, isLocalhost } from 'src/utils/helpers'
import useGenericContext from 'src/hooks/useGenericContext'
import { ComponentTypeResponse } from '../Components/types'
import { CartSettingsUI, defaultConfig } from './CartSettingsPage'
import { getRoutePath } from 'src/routes'
import { getInitialPageConfig } from 'src/utils/getInitialPageConfig'
import { ContainerConfigInterpretor } from 'src/types/interpretor'
import {buildPreviewURL} from '../../utils/preview'

const CartSettingsPage: FC = () => {
  const history = useHistory()
  const { publishComponent, getComponent, refetchComponents, shop, currencyCode } = useGenericContext()

  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [iframeSrc, setIframeSrc] = useState('')

  // TODO: how should we handle selectedStore changes?
  const [originalConfig, setOriginalConfig] = useState<ComponentTypeResponse>()
  const [config, setConfig] = useState<ComponentTypeResponse | undefined>()

  const searchParams = useParams() as any

  const [hasDiff, setHasDiff] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [resizeState, setResizeState] = useState({ width: 400, height: 711 })
  const [componentId, setComponentId] = useState('')

  const onResize = (event, { element, size, handle }) => {
    setResizeState({ width: size.width, height: size.height })
  }

  const isLocalhostIframeName = 'isLocalhostIframe_CartSettingsPage'
  const [isLocalhostIframe, setIsLocalhostIframe] = useState(localStorage.getItem(isLocalhostIframeName) === 'true')

  const handleSetIsLocalhostIframe = ((val: boolean) => {
    setIframeLoaded(false)
    setIsLocalhostIframe(val)
    localStorage.setItem(isLocalhostIframeName, val ? 'true' : '')
  })

  useEffect(() => {
    const setComponentSettings = async () => {
      const response = await getComponent('', 'CART')
      if (!(response?.data)) return
      const componentSettings = response?.data[0]
      if(!componentSettings) {
        return setConfig(defaultConfig)
      }
      setComponentId(componentSettings.componentId)
      setOriginalConfig(deepCloneJson(componentSettings))
      setConfig(deepCloneJson(componentSettings))
    }
    setComponentSettings()
  }, [getComponent, searchParams])

  useEffect(() => {
    if (originalConfig && config) {
      // TODO: make a helper function that compares the objects
      let newHasDiff = false

      newHasDiff = newHasDiff ||
        originalConfig.status !== config.status

      newHasDiff = newHasDiff ||
        (JSON.stringify(originalConfig) !== JSON.stringify(config))

      setHasDiff(newHasDiff)
    }
  }, [originalConfig, config])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (config) {
        // Our FE api settings check if ID is present, if not it creates a new component by calling a different endpoint
        const response = await publishComponent(config, componentId)

        if (response?.status === 200) {
          await refetchComponents()
          setLoading(false)
        } else {
          window.alert('Error creating/updating component')
          console.error('Error creating/updating component')
          setLoading(false)
        }
      } else {
        window.alert('Error creating/updating component - no config to send')
        console.error('Error creating/updating component - no config to send')
        setLoading(false)
      }
    }
    catch (error) {
      console.error('Error creating/updating component', error)
      window.alert('Error creating/updating component')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!iframeRef.current) return
    if (!iframeLoaded) return

    const cfg = getInitialPageConfig()
    cfg.layout = 'EMBEDDED_COMPONENTS'
    const payload = {
      type: 'setPageConfig',
      value: cfg,
    }
    const iframeWindow = iframeRef.current?.contentWindow
    iframeWindow?.postMessage(JSON.stringify({type: 'setForceRenderUnpublished', value: true}), '*')
    iframeWindow?.postMessage(JSON.stringify(payload), '*')
  }, [iframeRef, iframeLoaded, isLocalhostIframe])

  useEffect(() => {
    if (!iframeLoaded || !config) return

    const iframeWindow = iframeRef.current?.contentWindow
    const payload: { type: string, value: ContainerConfigInterpretor[] } = {
      type: 'setContainersPromise',
      value: [config],
    }
    setTimeout(() => {
      iframeWindow?.postMessage(JSON.stringify(payload), '*')
    }, 1000)
  }, [iframeLoaded, iframeRef, config])

  useEffect(() => {
    const fetchIframeSrc = async () => {
      const src = await buildPreviewURL(shop, '', 'cid=CART', isLocalhost && isLocalhostIframe)
      setIframeSrc(src)
    }

    void fetchIframeSrc()
  }, [shop, isLocalhost, isLocalhostIframe])

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        height: `calc(100%)`,
        alignItems: 'stretch',
        justifyContent: 'space-between',
      }}>
        <Paper sx={{
          overflow: 'auto',
          minWidth: 280,
          padding: '24px',
          textAlign: 'center',
          flex: 1,
          mb: '1px',
          mr: '1px',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}>

            {isLocalhost &&
              <Box>
                <input checked={isLocalhostIframe} onChange={() => handleSetIsLocalhostIframe(!isLocalhostIframe)} type='checkbox' name='localhost' id='localhost' />
                <label htmlFor='localhost' style={{
                  marginLeft: 10,
                }}>
                  Localhost preview
                </label>
              </Box>
            }

            <CartSettingsUI
              config={config as any}
              setConfig={setConfig as any}
              currencyCode={currencyCode}
            />

            <Box sx={{
              paddingTop: '8px',
              display: 'flex',
              gap: '5px',
              justifyContent: 'space-between',
              alignSelf: 'end',
            }}>
              <Box>
                <Button color='secondary'
                  onClick={() => {
                    if (!hasDiff) {
                      history.push(getRoutePath('components'))
                    }
                    else {
                      const confirmed = window.confirm('Discard changes?')
                      if (confirmed) {
                        history.push(getRoutePath('components'))
                      }
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading}
                  variant='contained' color='primary'
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  {loading ? 'Updating...' : 'Update'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Paper sx={{
          overflow: 'auto',
          minWidth: 500,
          padding: '16px',
          textAlign: 'center',
          flex: 1,
        }}>
          <Resizable
            width={resizeState.width}
            height={resizeState.height}
            minConstraints={[300, 533]}
            maxConstraints={[1000, 1000]}
            onResize={onResize}
            onResizeStart={() => setIsResizing(true)}
            onResizeStop={() => setIsResizing(false)}
          >
            <Box
              className='box'
              sx={{
                margin: 'auto',
                width: `${resizeState.width}px`,
                height: `${resizeState.height}px`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  flex: 3,
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%',
                  contain: 'layout',
                  position: 'relative' as any,
                  outline: '1px solid lightgrey',
                }}
              >
                <iframe
                  onLoad={() => setIframeLoaded(true)}
                  ref={iframeRef}
                  title='Live preview'
                  style={{
                    pointerEvents: isResizing ? 'none' : undefined as unknown as any,
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    border: 0,
                    position: 'absolute' as any,
                  }}
                  src={iframeSrc}
                />
              </Box>
            </Box>
          </Resizable>
        </Paper>

      </Box>
    </Box>
  )
}

export default CartSettingsPage
