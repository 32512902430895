import React, { useState, useRef, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useHistory, useParams } from 'react-router-dom'
import { Resizable } from 'react-resizable'
import type { FC } from 'react'
import { Paper, Typography,   Button, TextField, Divider, Box, useTheme, FormControl, FormControlLabel, Checkbox } from '@mui/material'

import { deepCloneJson } from 'src/utils/helpers'
import useGenericContext from 'src/hooks/useGenericContext'
import { ContainerConfigInterpretor, PageConfig } from 'src/types/interpretor'
import { ComponentsPicker } from './ComponentsPicker'
import Spacer from 'src/components/Spacer'
import { ComponentTypeResponse } from '../Components/types'
import { getComponentBase } from './componentBase'
import { getInitialPageConfig } from 'src/utils/getInitialPageConfig'
import { AffectedPagesModal } from 'src/components/AffectedPagesModal'
import { getRoutePath } from 'src/routes'
import {buildPreviewURL} from '../../utils/preview'

const PREFIX = 'CreateComponent'

const classes = {
  root: `${PREFIX}-root`,
  paperLeft: `${PREFIX}-paperLeft`,
  paperRight: `${PREFIX}-paperRight`,
  formFields: `${PREFIX}-formFields`,
  actionButtons: `${PREFIX}-actionButtons`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    height: `calc(100%)`,
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },

  [`& .${classes.paperLeft}`]: {
    overflow: 'auto',
    minWidth: 500,
    padding: theme.spacing(4),
    textAlign: 'center',
    flex: 1,
  },

  [`& .${classes.paperRight}`]: {
    overflow: 'auto',
    minWidth: 500,
    padding: theme.spacing(4),
    textAlign: 'center',
    flex: 1,
  },

  [`& .${classes.formFields}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },

  [`& .${classes.actionButtons}`]: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    gap: '5px',
    justifyContent: 'space-between',
  },
}))

const CreateComponent: FC = () => {
  const theme = useTheme()

  const history = useHistory()
  const { publishComponent, getComponent, refetchComponents, shop, getAffectedPages, storePrimitiveSettings } = useGenericContext()

  const iframeRef = useRef<HTMLIFrameElement>(null)
  const internalNameRef = useRef<HTMLElement | null>(null)
  const [iframeSrc, setIframeSrc] = useState('')


  const [internalNameErrorString, setInternalNameErrorString] = useState('')

  const [iframeLoaded, setIframeLoaded] = useState(false)

  // TODO: how should we handle selectedStore changes?
  const [originalComponentConfig, setOriginalComponentConfig] = useState<ComponentTypeResponse>()
  const [componentConfig, setComponentConfig] = useState<ComponentTypeResponse>(getComponentBase('MANUAL_PRODUCT_SELECTION', storePrimitiveSettings, true))
  const [affectedPages, setAffectedPages] = useState<PageConfig[]>([])
  const [isLoadingAffectedPages, setIsLoadingAffectedPages] = useState(false)
  const [isAffectedPagesModalOpen, setIsAffectedPagesModalOpen] = useState(false)

  const searchParams = useParams() as any
  const componentId = searchParams.id

  const [internalName, setInternalName] = useState('')
  const [internalDescription, setInternalDescription] = useState('')

  const [hasDiff, setHasDiff] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [resizeState, setResizeState] = useState({ width: 400, height: 711 })
  const onResize = (event, { element, size, handle }) => {
    setResizeState({ width: size.width, height: size.height })
  }

  const isLocalhostIframeName = 'isLocalhostIframe'
  const [isLocalhost, setIsLocalhost] = useState(window.location.hostname === 'localhost')
  const [isLocalhostIframe, setIsLocalhostIframe] = useState(localStorage.getItem(isLocalhostIframeName) === 'true')

  const handleSetIsLocalhostIframe = ((val: boolean) => {
    setIsLocalhostIframe(val)
    localStorage.setItem(isLocalhostIframeName, val ? 'true' : '')
  })

  useEffect(() => {
    if (componentConfig.internalName !== internalName ||
      componentConfig.internalDescription !== internalDescription
    ) {
      componentConfig.internalName = internalName
      componentConfig.internalDescription = internalDescription

      setComponentConfig({...componentConfig})
    }
  }, [internalName, internalDescription, componentConfig, setComponentConfig])

  useEffect(() => {
    if (internalNameErrorString && componentConfig.internalName)
      setInternalNameErrorString('')
  }, [internalNameErrorString, componentConfig.internalName])

  useEffect(() => {
    if (originalComponentConfig && componentConfig) {
    // TODO: make a helper function that compares the objects
      let newHasDiff = false

      newHasDiff = newHasDiff ||
      originalComponentConfig.status !== componentConfig.status

      newHasDiff = newHasDiff ||
      (JSON.stringify(originalComponentConfig) !== JSON.stringify(componentConfig))

      setHasDiff(newHasDiff)
    }
  }, [originalComponentConfig, componentConfig])

  useEffect(() => {

    if (!componentId) return

    const setComponentSettings = async () => {
      const response = await getComponent(componentId)
      if (!(response?.data)) return
      const componentSettings = response.data

      console.log('componentSettings', componentSettings)

      // NOTE: storing each as a deep cloned object
      // NOTE: keeping original reference for diff comparision
      setInternalName(componentSettings.internalName)
      setInternalDescription(componentSettings.internalDescription)
      setOriginalComponentConfig(deepCloneJson(componentSettings))
      setComponentConfig(deepCloneJson(componentSettings))
    }

    setComponentSettings()

  }, [componentId])

  // This is just for a base screen on preview
  useEffect(() => {
    if (!iframeLoaded) return

    const iframeWindow = iframeRef.current?.contentWindow
    const cfg = getInitialPageConfig()
    cfg.layout = 'EMBEDDED_COMPONENTS'
    const payload = {
      // TODO: rename in interpreter to storefront
      type: 'setPageConfig',
      value: cfg,
    }
    iframeWindow?.postMessage(JSON.stringify({type: 'setForceRenderUnpublished', value: true}), '*')
    iframeWindow?.postMessage(JSON.stringify(payload), '*')
  }, [iframeLoaded])

  useEffect(() => {
    if (!iframeLoaded || !componentConfig) return

    const iframeWindow = iframeRef.current?.contentWindow
    const payload: { type: string, value: ContainerConfigInterpretor[] } = {
      type: 'setContainersPromise',
      value: [componentConfig],
    }
    console.log('setContainersPromise', payload, {iframeWindow})
    setTimeout(() => {
      iframeWindow?.postMessage(JSON.stringify(payload), '*')
    }, 1000)
  }, [componentConfig, iframeLoaded])

  const handleSubmit = async () => {
    try {
      if(componentConfig) {
        // Our FE api settings check if ID is present, if not it creates a new component by calling a different endpoint
        const response = await publishComponent(componentConfig, componentId)

        if (response?.status === 200) {
          await refetchComponents()
          history.push(getRoutePath('components'))
        } else {
          window.alert('Error creating/updating component')
          console.error('Error creating/updating component')
        }
      } else {
        window.alert('Error creating/updating component - no config to send')
        console.error('Error creating/updating component - no config to send')
      }
    }
    catch (error) {
      console.error('Error creating/updating component', error)
      window.alert('Error creating/updating component')
    }
  }

  useEffect(() => {
    const fetchIframeSrc = async () => {


      const src = await buildPreviewURL(shop, '', `cid=${componentId || ''}`, isLocalhost && isLocalhostIframe)
      setIframeSrc(src)
    }

    void fetchIframeSrc()
  }, [shop, isLocalhost, isLocalhostIframe, componentId])


  const validateInternalName = (): boolean => {
    if (!componentConfig.internalName.trim()) {
      setInternalNameErrorString('Please Enter an Internal Name')
      internalNameRef.current?.focus()
      return false
    }
    return true
  }

  return (
    <Root>
      <div className={classes.root}>
        <Paper className={classes.paperLeft}>
          <div className={classes.formFields}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
            >
              <Typography variant='h4'
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  padding: '6px 0px',
                }}
              >
                {componentId ? 'Update ' : 'Create '}
                Component
              </Typography>
            </Box>

            <Divider style={{ marginBottom: '12px' }} />
            <Typography variant='body1'>
              {componentId ? 'Update ' : 'Create '} a component which you can use across all campaign stores.
            </Typography>
            <Spacer space={4} />
            {
              !isLocalhost ? '' :
                <FormControlLabel
                  label='localhost iframe'
                  control={
                    <Checkbox
                      checked={isLocalhostIframe}
                      onChange={(e) => handleSetIsLocalhostIframe(e.target.checked)}
                    />
                  }
                />
            }

            <div style={{ fontWeight: 'bold'}}>Internal Name</div>
            <div style={{ paddingBottom: theme.spacing(1)}}>This name is used for component identification</div>
            <FormControl>
              <TextField
                label='Internal Name'
                inputRef={internalNameRef}
                error={!!internalNameErrorString}
                helperText={internalNameErrorString}
                id='name'
                name='name'
                size='small'
                value={internalName}
                aria-describedby='component-name'
                type='text'
                variant='outlined'
                required
                onChange={(e) => setInternalName(e.currentTarget.value)}
              />
            </FormControl>

            <Spacer space={2} />

            <div style={{ fontWeight: 'bold'}}>Description</div>
            <div style={{ paddingBottom: theme.spacing(1)}}>Describe the component in greater detail</div>
            <FormControl>
              <TextField
                multiline
                label='Description'
                maxRows={5}
                id='description'
                name='description'
                size='small'
                value={internalDescription}
                aria-describedby='component-description'
                type='text'
                variant='outlined'
                required
                onChange={(e) => setInternalDescription(e.currentTarget.value)}
              />
            </FormControl>
            <Spacer space={2} />

            <div style={{width: '100%'}}>
              <span style={{ fontWeight: 'bold' }}>
                Component configuration
              </span>
              <div style={{ paddingBottom: theme.spacing(1)}}>Configure how this component is shown to customers</div>
              <ComponentsPicker
                layout=''
                hideComponentTypeDropdown={!!componentId}
                defaultVisibleInLibrary
                componentConfig={componentConfig}
                setComponentConfig={setComponentConfig}
              />
            </div>

            <div className={classes.actionButtons}>
              <div></div>
              <div>
                <Button color="secondary"
                  onClick={() => {
                    if (!hasDiff) {
                      history.push(getRoutePath('components'))
                    }
                    else {
                      const confirmed = window.confirm('Discard changes?')
                      if (confirmed) {
                        history.push(getRoutePath('components'))
                      }
                    }
                  }}
                >
                  Cancel
                </Button>
                {componentId ?
                  <Button
                    variant="contained" color="secondary"
                    onClick={() => {
                      const isValidated = validateInternalName()
                      if (!isValidated) return

                      setIsAffectedPagesModalOpen(true)
                      setIsLoadingAffectedPages(true)
                      getAffectedPages(componentId).then(pages => {
                        setAffectedPages(pages || [])
                        setIsLoadingAffectedPages(false)
                      })
                    }}
                  >
                  Update
                  </Button>
                  :
                  <Button
                    variant="contained" color="secondary"
                    onClick={() => {
                      const isValidated = validateInternalName()
                      if (!isValidated) return

                      handleSubmit()
                    }}
                  >
                    Create
                  </Button>
                }
              </div>
            </div>
          </div>
        </Paper>

        <Paper className={classes.paperRight}>
          <Resizable
            width={resizeState.width}
            height={resizeState.height}
            minConstraints={[300, 533]}
            maxConstraints={[1000, 1000]}
            onResize={onResize}
            onResizeStart={() => setIsResizing(true)}
            onResizeStop={() => setIsResizing(false)}
          >
            <div
              className="box"
              style={{
                margin: 'auto',
                width: `${resizeState.width}px`,
                height: `${resizeState.height}px`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >

              <div
                style={{
                  flex: 3,
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%',
                  contain: 'layout',
                  position: 'relative' as any,
                  outline: '1px solid lightgrey',
                }}
              >
                <iframe
                  onLoad={() => setIframeLoaded(true)}
                  ref={iframeRef}
                  title="Live preview"
                  style={{
                    pointerEvents: isResizing ? 'none' : undefined as unknown as any,
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    border: 0,
                    position: 'absolute' as any,
                  }}
                  src={iframeSrc}
                />
              </div>
            </div>
          </Resizable>
        </Paper>

        <AffectedPagesModal
          isOpen={isAffectedPagesModalOpen}
          setIsOpen={setIsAffectedPagesModalOpen}
          isLoadingAffectedPages={isLoadingAffectedPages}
          affectedPages={affectedPages}
          editedComponentConfig={componentConfig}
          setReadyForUpdate={() => handleSubmit()}
          pageConfig={undefined}
        />

      </div>
    </Root>
  )
}

export default CreateComponent
