import React, { FC, useState } from 'react'
import { SocialPicker } from 'src/views/CreateStorefront/components/SocialPicker'
import { ComponentTypeResponse, getFullFields, getMinifiedFields } from 'src/views/Components/types'
import { PageConfig } from 'src/types/interpretor'
import { ManualProductSelectionInput } from './ManualProductSelection'
import { ProductItemFull } from 'src/components/ProductSelectorLarge'
import { DisplayTypesEnum, StorePrimitiveSettings } from 'src/views/ComponentSettingsPage/types'

type Props = {
  componentConfig: ComponentTypeResponse & { contentConfiguration: { type: 'MEDIA_PRIMITIVE' } }
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  currentSelectedProductMap: Record<string, ProductItemFull>
  storePrimitiveSettings: StorePrimitiveSettings | undefined
}

export const Banner: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  currentSelectedProductMap,
  storePrimitiveSettings,
}) => {
  const storePrimitiveFields = storePrimitiveSettings?.product.displayType === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getFullFields()
  const bannerProductSelectionContainer: ComponentTypeResponse = {
    componentId: '',
    createdAt: 0,
    updatedAt: 0,
    componentType: 'MANUAL_PRODUCT_SELECTION',
    containerType: componentConfig.contentConfiguration.value.uiSettings.productContainerType || storePrimitiveSettings?.product.containerType || 'CAROUSEL',
    internalName: '',
    title: '',
    contentType: 'PRODUCT',
    contentConfiguration: {
      type: 'PRODUCT_PRIMITIVE',
      value: {
        uiSettings: {
          fields: componentConfig.contentConfiguration.value.uiSettings.productFields || storePrimitiveFields,
          imageFit: componentConfig.contentConfiguration.value.uiSettings.imageFit || storePrimitiveSettings?.product.uiSettings.imageFit,
        },
        apiSettings: {
          productIds: componentConfig.contentConfiguration.type === 'MEDIA_PRIMITIVE' ? componentConfig.contentConfiguration.value.apiSettings.productIds || [] : [],
        },
      },
    },
    visibleInLibrary: true,
    internalDescription: '',
    status: 'PUBLISHED',
    class:'SAVED',
  }
  const [productsComponentConfig, setProductsComponentConfig] = useState(bannerProductSelectionContainer)

  const handleHeadlineUpdate = (headline: string) => {
    const newComponent = { ...componentConfig }
    newComponent.contentConfiguration.value.uiSettings.headline = headline
    setComponentConfig(newComponent)
  }

  const handleSubheadlineUpdate = (subheadline: string) => {
    const newComponent = { ...componentConfig }
    newComponent.contentConfiguration.value.uiSettings.subtitle = subheadline
    setComponentConfig(newComponent)
  }

  const handleCampaignMediasUpdate = (campaignMedias: PageConfig['campaignMedias']) => {
    const newComponent = { ...componentConfig }
    newComponent.contentConfiguration.value.apiSettings.sources = campaignMedias
    setComponentConfig(newComponent)
  }

  const setManualProductSelectionConfig = ((manualProductSelectionConfig: ComponentTypeResponse) => {
    const newComponent: ComponentTypeResponse = { ...componentConfig }
    if (manualProductSelectionConfig.contentConfiguration.type !== 'PRODUCT_PRIMITIVE' || newComponent.contentConfiguration.type !== 'MEDIA_PRIMITIVE') return
    newComponent.contentConfiguration.value.apiSettings.productIds = manualProductSelectionConfig.contentConfiguration.value.apiSettings.productIds
    newComponent.contentConfiguration.value.uiSettings.imageFit = manualProductSelectionConfig.contentConfiguration.value.uiSettings.imageFit
    newComponent.contentConfiguration.value.uiSettings.productFields= manualProductSelectionConfig.contentConfiguration.value.uiSettings.fields
    newComponent.contentConfiguration.value.uiSettings.productContainerType= manualProductSelectionConfig.containerType
    setComponentConfig(newComponent)
    setProductsComponentConfig(manualProductSelectionConfig)
  })

  return (
    <div>
      <SocialPicker
        defaultOpen={false}
        headline={componentConfig.contentConfiguration.value.uiSettings.headline}
        onHeadlineChange={handleHeadlineUpdate}
        subheadline={componentConfig.contentConfiguration.value.uiSettings.subtitle}
        onSubheadlineChange={handleSubheadlineUpdate}
        campaignMedias={componentConfig.contentConfiguration.value.apiSettings.sources}
        onCampaignMediasChange={handleCampaignMediasUpdate}
      />

      <ManualProductSelectionInput
        componentConfig={productsComponentConfig}
        setComponentConfig={setManualProductSelectionConfig}
        currentSelectedProductMap={currentSelectedProductMap}
        backgroundGradient={''}
        openWhenNotSelected={false}
      />
    </div>
  )
}
