import React, { FC, useEffect, useState } from 'react'
import { Box, MenuItem, TextField } from '@mui/material'
import { Category } from 'src/contexts/types'
import { ProductItemFull } from 'src/components/ProductSelectorLarge'
import { ComponentTypeResponse } from 'src/views/Components/types'
import { getComponentBase } from '../componentBase'
import { ComponentType } from 'storefront-interpreter/src/api/components'
import { DeepRequiredStorePrimitiveSettings } from 'src/views/ComponentSettingsPage/types'

type ComponentListItem = {value: ComponentType, title: string}

const componentsList: Array<ComponentListItem> = [
  {value: 'ALTERNATES_IN_CATEGORY', title: 'Alternates In Category'},
  {value: 'SIMILAR_PRODUCTS', title: 'Similar Products'},
  {value: 'VISUAL_COLLECTION', title:'Visual Collection'},
  {value: 'ALL_PRODUCTS_IN_CATEGORY', title: 'All Products In Category'},
  {value: 'MANUAL_PRODUCT_SELECTION', title: 'Manual Product Selection'},
  {value: 'MANUAL_CATEGORY_NAVIGATION', title: 'Manual Category Navigation'},
  {value: 'HTML', title: 'HTML'},
  {value: 'BANNER', title: 'Banner'},
  {value: 'YOUTUBE', title: 'Youtube'},

  // should not allow creating a new reviews component for now, but will want to in the future.
  //{value:'REVIEWS', title: 'Product Reviews'},
]

export const TitleAndTypeInput: FC<{
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  currentSelectedProductMap: Record<string, ProductItemFull>
  currentSelectedCategoryMap: Record<string, Category>
  defaultVisibleInLibrary: boolean
  componentsListFilter: (componentListItem: ComponentListItem) => boolean
  storePrimitiveSettings: DeepRequiredStorePrimitiveSettings
  hideComponentTypeDropdown?: boolean
}> = ({
  componentConfig,
  setComponentConfig,
  currentSelectedProductMap,
  currentSelectedCategoryMap,
  defaultVisibleInLibrary,
  componentsListFilter,
  storePrimitiveSettings,
  hideComponentTypeDropdown,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >

        <TextField
          style={{
            flex: '1',
            marginRight: !hideComponentTypeDropdown ? '12px' : '',
          }}
          id='Component Label'
          name='Component Label'
          size='small' margin='dense' type='string' variant='outlined'
          value={componentConfig.title}
          onChange={(e) => {
            const newComponent = {...componentConfig}
            newComponent.title = e.target.value
            setComponentConfig(newComponent)
          }}
          label={'Component Label'}
        />

        {!hideComponentTypeDropdown && <TextField
          style={{
            flex: '1',
          }}
          select
          id='Component Type'
          name='Component Type'
          size='small' margin='dense' type='string' variant='outlined'
          value={componentConfig.componentType}

          label='Component Type'

          onChange={(e) => {
            const val = e.target.value as ComponentType
            const c = getComponentBase(val, storePrimitiveSettings, defaultVisibleInLibrary)

            if (
              val === 'ALTERNATES_IN_CATEGORY' ||
              val === 'SIMILAR_PRODUCTS' ||
              val === 'VISUAL_COLLECTION' ||
              val === 'ALL_PUBLISHED_CAMPAIGNS' ||
              val === 'MANUAL_PRODUCT_SELECTION' ||
              val === 'MANUAL_CATEGORY_NAVIGATION' ||
              val === 'HTML' ||
              val === 'EMAIL_CAPTURE' ||
              val === 'BACK_IN_STOCK_NOTIFICATION' ||
              val === 'BANNER' ||
              val === 'YOUTUBE' ||
              val === 'REVIEWS'
            ) {
            }
            else if (val === 'ALL_PRODUCTS_IN_CATEGORY') {
              if (c.contentConfiguration.type === 'CATEGORY_PRIMITIVE') {
                c.contentConfiguration.value.apiSettings.categoryIds = Object.values(currentSelectedCategoryMap).length ? [Object.values(currentSelectedCategoryMap)[0].id] : []
              }
            }
            else {
              throw new Error('Unknown component type: ' + val)
            }

            c.title = componentConfig.title
            c.componentId = componentConfig.componentId
            c.internalName = componentConfig.internalName
            c.internalDescription = componentConfig.internalDescription
            c.visibleInLibrary = componentConfig.visibleInLibrary
            return setComponentConfig(c)
          }}
        >
          {
            componentsList
              .filter(componentsListFilter)
              .map(option => <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>)
          }
        </TextField>}
      </Box>

    </Box>
  )
}
