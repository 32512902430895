import { ImageType as ImageType_original } from 'storefront-interpreter/src/config'
import { RawImage as RawImage_original } from 'storefront-interpreter/src/config'
import { RawProduct as RawProduct_original } from 'storefront-interpreter/src/config'
import { PageConfig as PageConfig_original} from 'storefront-interpreter/src/config'
import { SortBy} from 'storefront-interpreter/src/config'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'
import { ComponentType as ComponentType_original } from 'storefront-interpreter/src/api/components'
import { ContainerType as ContainerType_original } from 'storefront-interpreter/src/api/components'
import { ExperienceConfig as StorefrontConfig_original } from 'storefront-interpreter/src/config'

export type ImageType = ImageType_original
export type RawImage = RawImage_original
export type RawProduct = RawProduct_original
export type ContainerConfigInterpretor = ContainerConfig
export type StorefrontConfig = StorefrontConfig_original & {redirect_url : string} & {customer: string}
export type ComponentType = ComponentType_original
export type ContainerType = ContainerType_original

// TODO: define this in the interpreter and plug it into the PageConfig type there.
export enum PageStatus {
  Draft = 'DRAFT',
  Scheduled = 'SCHEDULED',
  Active ='ACTIVE',
  Completed = 'COMPLETED',
}

export type PageConfig = PageConfig_original & {
  isLegacy?: boolean
}

// TODO: is there a better place for this constant?
export const DefaultSortBy: SortBy = {
  field: 'sales',
  direction: 'desc',
}
