import React from 'react'
import {
  Box,
  Typography,
  Paper,
} from '@mui/material'
import { OnPostOrAdSelectedProps, SocialPicker } from '../../CreateStorefront/components/SocialPicker'
import { Ad, Post } from 'src/contexts/types'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'

interface InlineSocialPickerProps {
  loading: boolean
  onPostOrAdSelected: (p: OnPostOrAdSelectedProps) => void
  manualTabOnClick?: () => void
}

export const InlineSocialPicker: React.FC<InlineSocialPickerProps> = ({
  loading,
  onPostOrAdSelected,
  manualTabOnClick,
}) => {
  return (
    <Paper sx={{
      margin: 4,
      marginTop: 0,
      padding: 2,
      borderRadius: 1,
      position: 'relative',
      '& .MuiAutocomplete-popper': {
        position: 'static !important',
        transform: 'none !important',
      },
    }} elevation={0}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Typography variant="h6">
            Create from Social Post
          </Typography>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
          '& > div': { minHeight: 0 },
        }}>
          <SdxLoading />
        </Box>
      ) : (
        <SocialPicker
          manualTabOnClick={manualTabOnClick}
          headline=""
          onHeadlineChange={() => {}}
          subheadline=""
          onSubheadlineChange={() => {}}
          campaignMedias={[]}
          onCampaignMediasChange={() => {}}
          onPostOrAdSelected={onPostOrAdSelected}
          keepOpen={true}
        />
      )}
    </Paper>
  )
}
