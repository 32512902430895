export type CurrencyCode = 'USD' | 'EUR' | 'GBP'

export const currencySymbolMap: Record<CurrencyCode, string> = {
  USD: '$',
  EUR: '€',
  GBP: '£',
}

export const getCurrencySymbol = (currencyCode: CurrencyCode): string => {
  const symbol = currencySymbolMap[currencyCode]
  if (!symbol) {
    throw new Error(`Unsupported currency code: ${currencyCode}`)
  }

  return symbol
}
