import React, { useState, useEffect, createContext } from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container, Grid, Typography, Button, FormControl,
  Paper, TextField, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, TableContainer,
  Table, TableBody, TableCell, TableRow, TableHead,
  IconButton, Box, Tabs, Tab,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Skeleton } from '@mui/material'
import { logging } from 'src/utils/logging'
import Page from 'src/components/Page'
import { AlertType } from 'src/types/components'

import { useTranslation } from 'react-i18next'
import useGenericContext from 'src/hooks/useGenericContext'
import { ButtonSettingsType, CustomDomainPayload, CustomDomainSettingsResponse, HeadScriptPayload, StoreSettingsPayload } from 'src/types/storeSettings'
import Spacer from 'src/components/Spacer'
import StoreIntegrations from '../StoreIntegrations'
import { getRoutePath } from 'src/routes'
import EditExitAttribution from '../EditExitAttribution'
import FooterSettingsPage from '../FooterSettingsPage'
import HeaderSettingsPage from '../HeaderSettingsPage'
import EditPermalinkConfig from '../EditPermalinkConfig'
import { useFlags } from 'launchdarkly-react-client-sdk'
import SdxColorPicker from '../Components/SdxColorPicker'
import { StorePrimitiveSettings } from '../ComponentSettingsPage/types'
import ComponentSettingsPage from '../ComponentSettingsPage'
import { scriptBaseURL } from 'src/config'
import ScriptSettings from './ScriptSettings'
import ButtonSettings from './ButtonSettings'
import CartSettingsPage from '../CartSettingsPage'
import { MerchantPlan } from 'src/contexts/types'

const PREFIX = 'StoreSettings'

const classes = {
  root: `${PREFIX}-root`,
  form: `${PREFIX}-form`,
  formSkeleton: `${PREFIX}-formSkeleton`,
  formText: `${PREFIX}-formText`,
  formSubText: `${PREFIX}-formSubText`,
  trackingScriptsField: `${PREFIX}-trackingScriptsField`,
  header: `${PREFIX}-header`,
  addIcon: `${PREFIX}-addIcon`,
  logoGroup: `${PREFIX}-logoGroup`,
  logoItem: `${PREFIX}-logoItem`,
  tableCell: `${PREFIX}-tableCell`,
  closeButton: `${PREFIX}-closeButton`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    marginBottom: '16px',
  },

  [`& .${classes.form}`]: {
    padding: theme.spacing(3),
    width: '100%',
    marginBottom: '16px',
  },

  [`& .${classes.formSkeleton}`]: {
    width: '100%',
  },

  [`& .${classes.formText}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.formSubText}`]: {
    paddingBottom: theme.spacing(1),
    fontSize: 12,
    fontStyle: 'italic',
  },

  [`& .${classes.trackingScriptsField}`]: {
    resize: 'none',
    padding: '18.5px 14px',
  },

  [`& .${classes.header}`]: {
    fontSize: 18,
    marginBottom: 14,
  },

  [`& .${classes.addIcon}`]: {
    width: 16,
    marginRight: 5,
    '& path': {
      stroke: 'white',
    },
  },

  [`& .${classes.logoGroup}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.logoItem}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },

  [`& .${classes.tableCell}`]: {
    padding: '6px 5px 6px 5px',
  },

  [`& .${classes.closeButton}`]: {
    paddingLeft: 0,
    color: theme.palette.grey[500],
  },
}))

const formatColor = (str: string) => {
  str = str.replaceAll(' ', '') // remove spaces
  str = str.toLowerCase()
  return str
}
export const UsersSettingsContext = createContext<{ refetchUsers: () => Promise<void>, showAlert: (alertData: { message: string, alertType: AlertType }) => void }>({ refetchUsers: async () => undefined, showAlert: () => null })

interface StoreSettingsType {
  logo: any
  primaryColor: string
  secondaryColor: string
  logoBackgroundColor: string

  mainFontName: string
  mainFontUrl: string
  headerFontName: string
  headerFontUrl: string

  buyNow: ButtonSettingsType
  addToCart: ButtonSettingsType
}

export interface TrackingScriptsDataPayload {
  name: string
  content: string
}
export interface TrackingScriptsPayload {
  configName: string
  client: string
  tenant: string
  data: TrackingScriptsDataPayload[]
}

const StoreSettings: FC = () => {

  const { t } = useTranslation()
  const history = useHistory()
  const {
    shop,
    saveStoreSettings,
    getStoreSettings,
    saveCustomDomain,
    getActiveCustomDomain,
    getTrackingScripts,
    saveTrackingScripts,
    saveHeadTrackingScripts,
  } = useGenericContext()

  const {analytics} = useFlags()

  const [storeSettingsLoading, setStoreSettingsLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [customDomainloading, setCustomDomainloading] = useState(true)
  const [customDomainSubmitloading, setCustomDomainSubmitloading] = useState(false)

  const { merchantDetails, storePrimitiveSettings, saveStorePrimitiveSettings } = useGenericContext()

  const isEnterprise = merchantDetails.plan === MerchantPlan.Enterprise
  const isBase = merchantDetails.plan === MerchantPlan.Base

  const [activeTab, setActiveTab] = useState(0)
  // TODO: each settings and these tabs should go in a separate component
  const [styleTab, setStyleTab] = useState(0)

  const [domainDialog, setDomainDialog] = useState(false)
  const [customDomain, setCustomDomain] = useState('')
  const [customDomainResponse, setCustomDomainResponse] = useState<CustomDomainSettingsResponse[]>([])

  const [trackingScripts, setTrackingScripts] = useState('')
  const [headerTrackingScripts, setHeaderTrackingScripts] = useState('')
  const [trackingScriptConfigLoading, setTrackingScriptConfigLoading] = useState(false)
  const [headerTrackingScriptConfigLoading, setHeaderTrackingScriptConfigLoading] = useState(false)
  const [currentLogo, setCurrentLogo] = useState('')
  const [formDetails, setFormDetails] = React.useState<StoreSettingsType>({
    logo: '',
    primaryColor: '',
    secondaryColor: '',
    logoBackgroundColor: '',
    mainFontName: '',
    mainFontUrl: '',
    headerFontName: '',
    headerFontUrl: '',
    buyNow: {
      text: '',
      backgroundColor: '',
      backgroundHoverColor: '',
      textColor: '',
      textHoverColor: '',
      outlineColor: '',
      outlineHoverColor: '',
      outlineWidthPixels: 0,
      fontUrl: '',
    },
    addToCart: {
      text: '',
      backgroundColor: '',
      backgroundHoverColor: '',
      textColor: '',
      textHoverColor: '',
      outlineColor: '',
      outlineHoverColor: '',
      outlineWidthPixels: 0,
      fontUrl: '',
    },
  })

  const flag = useFlags()

  useEffect(() => {
    async function fetchStoreSettings() {
      setStoreSettingsLoading(true)
      const response = await getStoreSettings()

      response?.data && setFormDetails({
        logo: '',
        primaryColor: response?.data.colors.primary,
        secondaryColor: response?.data.colors.secondary,
        logoBackgroundColor: response?.data.colors.logoBackground,

        mainFontName: response?.data.fonts.main.name,
        mainFontUrl: response?.data.fonts.main.url,
        headerFontName: response?.data.fonts.header.name,
        headerFontUrl: response?.data.fonts.header.url,

        buyNow: {
          text: response?.data.buyNow.text,
          backgroundColor: response?.data.buyNow.backgroundColor,
          backgroundHoverColor: response?.data.buyNow.backgroundHoverColor,
          textColor: response?.data.buyNow.textColor,
          textHoverColor: response?.data.buyNow.textHoverColor,
          outlineColor: response?.data.buyNow.outlineColor,
          outlineHoverColor: response?.data.buyNow.outlineHoverColor,
          outlineWidthPixels: response?.data.buyNow.outlineWidthPixels,
          fontUrl: response?.data.buyNow.fontUrl,
        },

        addToCart: {
          text: response?.data.addToCart.text,
          backgroundColor: response?.data.addToCart.backgroundColor,
          backgroundHoverColor: response?.data.addToCart.backgroundHoverColor,
          textColor: response?.data.addToCart.textColor,
          textHoverColor: response?.data.addToCart.textHoverColor,
          outlineColor: response?.data.addToCart.outlineColor,
          outlineHoverColor: response?.data.addToCart.outlineHoverColor,
          outlineWidthPixels: response?.data.addToCart.outlineWidthPixels,
          fontUrl: response?.data.addToCart.fontUrl,
        },
      })
      if (response?.data?.logoUrl) {
        setCurrentLogo(response?.data?.logoUrl)
      }
      setStoreSettingsLoading(false)
    }


    async function fetchCustomDomain() {
      setCustomDomainloading(true)
      const response = await getActiveCustomDomain()
      response?.data && setCustomDomain(response?.data?.domain)
      setCustomDomainloading(false)
    }

    async function fetchTrackingScripts() {
      setTrackingScriptConfigLoading(true)
      try {
        const response = await getTrackingScripts()
        response?.data?.response?.data[0]?.content && setTrackingScripts(response?.data?.response?.data[0]?.content)
      }
      catch (error) {
        logging(error, { tags: { section: 'storesettings' } })
      }
      finally {
        setTrackingScriptConfigLoading(false)
      }
    }

    async function fetchHeaderTrackingScripts() {
      setHeaderTrackingScriptConfigLoading(true)
      try {
        const scriptUrl = `https://${scriptBaseURL}/head_scripts/${shop}/head.html`

        const response = await fetch(scriptUrl)
        const data = await response.text()

        // If the file has not been created we get a 403 Forbidden response. We should ignore this and only set the header scripts if we get a 200 OK response.
        if (response.ok) {
          setHeaderTrackingScripts(data)
        }
      }
      catch (error) {
        logging(error, { tags: { section: 'storesettings' } })
      }
      finally {
        setHeaderTrackingScriptConfigLoading(false)
      }
    }

    merchantDetails.plan === 'ENTERPRISE' && fetchStoreSettings()
    merchantDetails.plan === 'ENTERPRISE' && fetchCustomDomain()
    merchantDetails.plan === 'ENTERPRISE' && fetchTrackingScripts()
    merchantDetails.plan === 'ENTERPRISE' && fetchHeaderTrackingScripts()
  }, [getActiveCustomDomain, getStoreSettings, getTrackingScripts, merchantDetails.plan, shop])

  const submitSettings = async (e) => {
    e.preventDefault()
    setSubmitLoading(true)
    const config: StoreSettingsPayload = {
      colors: {
        primary: formDetails.primaryColor,
        secondary: formDetails.secondaryColor,
        logoBackground: formDetails.logoBackgroundColor,
      },
      fonts: {
        main: {
          name: formDetails.mainFontName,
          url: formDetails.mainFontUrl,
        },
        header: {
          name: formDetails.headerFontName,
          url: formDetails.headerFontUrl,
        },
      },
      addToCart: {
        text: formDetails.addToCart.text,
        backgroundColor: formDetails.addToCart.backgroundColor,
        backgroundHoverColor: formDetails.addToCart.backgroundHoverColor,
        textColor: formDetails.addToCart.textColor,
        textHoverColor: formDetails.addToCart.textHoverColor,
        outlineColor: formDetails.addToCart.outlineColor,
        outlineHoverColor: formDetails.addToCart.outlineHoverColor,
        outlineWidthPixels: formDetails.addToCart.outlineWidthPixels,
        fontUrl: formDetails.addToCart.fontUrl,
      },
      buyNow: {
        text: formDetails.buyNow.text,
        backgroundColor: formDetails.buyNow.backgroundColor,
        backgroundHoverColor: formDetails.buyNow.backgroundHoverColor,
        textColor: formDetails.buyNow.textColor,
        textHoverColor: formDetails.buyNow.textHoverColor,
        outlineColor: formDetails.buyNow.outlineColor,
        outlineHoverColor: formDetails.buyNow.outlineHoverColor,
        outlineWidthPixels: formDetails.buyNow.outlineWidthPixels,
        fontUrl: formDetails.buyNow.fontUrl,
      },
    }

    const logo: any = formDetails.logo
    try {
      const response = await saveStoreSettings({ config, logo })
      if (response?.status === 201) {
        window.alert('Settings saved successfully')
      } else {
        window.alert('Error saving settings')
      }
      setSubmitLoading(false)
    }
    catch (error) {
      setSubmitLoading(false)
      window.alert('Error saving settings')
      logging(error, { tags: { section: 'storesettings' } })
    }
  }

  const submitCustomDomain = async (e) => {
    e.preventDefault()
    const customDomainPayload: CustomDomainPayload = {
      domain: customDomain,
    }
    try {
      setCustomDomainSubmitloading(true)
      const response = await saveCustomDomain(customDomainPayload)
      if (response?.status === 201) {
        response?.data && setCustomDomainResponse(response.data)
        response?.data && setDomainDialog(true)
      }
      else {
        setCustomDomainSubmitloading(false)
        window.alert('Error saving custom domain')
      }
    }
    catch (error) {
      console.warn('Error saving custom domain', error)
      logging(error, { tags: { section: 'storesettings' } })
    }
  }

  const handleDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormDetails({ ...formDetails, [name]: value })
  }

  const handleAddToCartDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormDetails({ ...formDetails, addToCart: { ...formDetails.addToCart, [name]: value } })
  }

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCustomDomain(value)
  }


  const submitTrackingScripts = async (e) => {
    e.preventDefault()
    setTrackingScriptConfigLoading(true)
    const trackingScriptsPayload: TrackingScriptsPayload = {
      configName: 'trackingPixel',
      client: 'shop',
      tenant: shop,
      data: [
        {
          name: 'Tracking scripts',
          content: trackingScripts,
        },
      ],
    }
    try {
      const response = await saveTrackingScripts(trackingScriptsPayload)
      if (response && typeof response === 'object' && response.status >= 200 && response.status < 400) {
      } else {
        window.alert('Error saving tracking scripts')
      }
    }
    catch (error) {
      console.warn('Error saving tracking scripts', error)
      logging(error, { tags: { section: 'storesettings' } })
    }
    finally {
      setTrackingScriptConfigLoading(false)
    }
  }

  const submitHeaderTrackingScripts = async (e) => {
    e.preventDefault()
    setHeaderTrackingScriptConfigLoading(true)

    const headTrackingScriptsPayload: HeadScriptPayload = {
      headScript: headerTrackingScripts,
    }

    try {
      const response = await saveHeadTrackingScripts(headTrackingScriptsPayload)
      if (response && typeof response === 'object' && response.status >= 200 && response.status < 400) {
      } else {
        window.alert('Error saving head tracking scripts')
      }
    }
    catch (error) {
      console.warn('Error saving head tracking scripts', error)
      logging(error, { tags: { section: 'storesettings' } })
    }
    finally {
      setHeaderTrackingScriptConfigLoading(false)
    }
  }

  const handleDomainDialogClose = () => setDomainDialog(false)

  const storefrontStyleSettings = <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '600px',
      width: { xs: '100%', md: '75%' },
    }}
  >
    <Box
      sx={{
        maxWidth: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '6px',
      }}
    >
      <Box
        sx={{
          minWidth: '200px',
          flexBasis: 'auto',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box className={classes.formSubText}>{t('Color of the storefront text')}</Box>
        <SdxColorPicker
          sx={{ flex: 1 }}
          label={t('Store primary color')}
          size='small'
          value={formDetails.primaryColor}
          onChange={(v) => { setFormDetails({ ...formDetails, primaryColor: v }) }}
        />
      </Box>

      <Box
        sx={{
          minWidth: '200px',
          flexBasis: 'auto',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box className={classes.formSubText}>{t('Color of the storefront buttons and headers')}</Box>
        <SdxColorPicker
          sx={{ flex: 1 }}
          label={t('Store secondary color')}
          size='small'
          value={formDetails.secondaryColor}
          onChange={(v) => { setFormDetails({ ...formDetails, secondaryColor: v }) }}
        />
      </Box>
    </Box>

    <TextField id='mainFontUrl' name='mainFontUrl' type='text'
      variant='outlined'
      size='small'
      label='Main font URL (Accepted Formats: .woff, .woff2, .ttf, .otf)'
      sx={{ flex: 2, mt: 2, mb: 2 }}
      onChange={handleDetailsChange}
      value={formDetails.mainFontUrl}
    />

    <TextField id='headerFontUrl' name='headerFontUrl' type='text'
      variant='outlined'
      size='small'
      label='Header font URL (Accepted Formats: .woff, .woff2, .ttf, .otf)'
      sx={{ flex: 2, mb: 2 }}
      onChange={handleDetailsChange}
      value={formDetails.headerFontUrl}
    />
  </Box>

  const storeSettings = (storeSettingsLoading ?
    <div className={classes.formSkeleton}>
      <Skeleton animation="wave" variant="rectangular" height={600} />
    </div> :
    <Paper>
      <Tabs
        sx={{
          background: 'white',
          '& .MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0.25,
          },
        }}
        variant='scrollable'
        allowScrollButtonsMobile
        value={styleTab}
        onChange={(e, value) => setStyleTab(value)}
      >
        <Tab tabIndex={0} label='Storefront' />
        <Tab tabIndex={1} label='Buy Now' />
        <Tab tabIndex={2} label='Add to Cart' />
      </Tabs>

      <Box sx={{p: 2}}>
        {styleTab === 0 && storefrontStyleSettings}
        {styleTab === 1 && <ButtonSettings
          settingsKey='buyNow'
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          classes={classes}
        />}
        {styleTab === 2 && <ButtonSettings
          settingsKey='addToCart'
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          classes={classes}
        />}

        <Button
          disabled={submitLoading}
          onClick={(e) => submitSettings(e)}
          color='secondary' variant='contained' type='submit'
        >
          {submitLoading ? t('Saving...') : t('Save')}
        </Button>
      </Box>
    </Paper>
  )

  const domainSettings = (customDomainloading ?
    <div className={classes.formSkeleton}>
      <Skeleton animation="wave" variant="rectangular" height={200} />
    </div> :
    <Paper className={classes.form}>
      <Typography variant="h3"
        color="textPrimary"
        className={classes.header}>
        {t(`Domain settings`)}
      </Typography>
      {customDomainResponse.length > 1 ?
        <div>
          <h2 style={{ fontWeight: 'bolder', marginBottom: '10px' }}>IMPORTANT: Copy and save the following</h2>
          <div>
            <p style={{ marginBottom: '10px' }}>
              Copy and save the following information. You will need it to configure your domain.
              Information is not longer available after you leave this page. </p>
            <p style={{ marginBottom: '10px' }}>
              Domain takes up to 10mins to be active after DNS configurations.
            </p>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>{t('name')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('value')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('type')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customDomainResponse.map((row) => (
                    <TableRow
                      key={row.name}
                    >
                      <TableCell className={classes.tableCell}>{row.name}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.value}
                      </TableCell>
                      <TableCell className={classes.tableCell}>{row.type}</TableCell>

                    </TableRow>
                  ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        :
        <form autoComplete="off" onSubmit={(e) => submitCustomDomain(e)}>
          <Grid container direction="column" spacing={3}>

            <Grid item>
              <div className={classes.formText}>{t('Custom URL domain (please input without scheme i.e. no `https` or `http` at beginning)')}</div>
              <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleDomainChange(e)
                    }}
                    id="customDomain"
                    name="customDomain"
                    value={customDomain}
                    aria-describedby="secondary-color"
                    type="text"
                    variant="outlined"
                    required
                    inputProps={{ pattern: '^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,20}$', title: 'Enter valid domain' }} />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <Spacer space={2} />
          <Button color="secondary" variant="contained" type="submit" disabled={customDomainSubmitloading}>
            {customDomainSubmitloading ? t('Saving...') : t('Save')}
          </Button>
        </form>}
    </Paper>
  )

  const scriptSettings = <ScriptSettings
    trackingScripts={trackingScripts}
    setTrackingScripts={setTrackingScripts}
    trackingScriptConfigLoading={trackingScriptConfigLoading}
    submitTrackingScripts={submitTrackingScripts}
    headerTrackingScripts={headerTrackingScripts}
    setHeaderTrackingScripts={setHeaderTrackingScripts}
    headerTrackingScriptConfigLoading={headerTrackingScriptConfigLoading}
    submitHeaderTrackingScripts={submitHeaderTrackingScripts}
    classes={classes}
  />

  return (
    <Root>
      <Page className={classes.root} title={t('Store settings')}>
        <Container fixed>
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            direction='row'
          >
            <Grid item>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  history.push(getRoutePath('home'))
                }}
                size="large">
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h3"
                color="textPrimary"
                className={classes.header}>
                {t(`Set Up for`)}&nbsp;{shop}
              </Typography>
            </Grid>
          </Grid>

          <Tabs
            sx={{
              background: 'white',
              '& .MuiTabs-scrollButtons.Mui-disabled': {
                opacity: 0.25,
              },
            }}
            variant='scrollable'
            allowScrollButtonsMobile
            value={activeTab}
            onChange={(e, value) => setActiveTab(value)}
          >
            {/* NOTE: these two are exclusive and either has to be at the default activeTab 0 */}
            {isBase && <Tab tabIndex={0} label='Integrations' />}
            {isEnterprise && <Tab tabIndex={0} label='Style' />}

            {isEnterprise && <Tab tabIndex={1} label='Cart' />}
            {isEnterprise && <Tab tabIndex={2} label='Component' />}
            {isEnterprise && <Tab tabIndex={3} label='Header' />}
            {isEnterprise && <Tab tabIndex={4} label='Domain' />}
            {isEnterprise && <Tab tabIndex={5} label='Scripts' />}
            {analytics && <Tab tabIndex={6} label='Exit Attribution' />}
            {isEnterprise && <Tab tabIndex={7} label='Footer' />}
            {flag.permalink_config && <Tab tabIndex={8} label='Permalink' />}
          </Tabs>

          <Box>
            {/* NOTE: these two are exclusive and either has to be at the default activeTab 0 */}
            {activeTab === 0 && isBase && <Box><StoreIntegrations noMargins /></Box>}
            {activeTab === 0 && isEnterprise && storeSettings}

            {activeTab === 1 && isEnterprise && <CartSettingsPage />}
            {activeTab === 2 && isEnterprise && <ComponentSettingsPage />}
            {activeTab === 3 && isEnterprise && <HeaderSettingsPage />}
            {activeTab === 4 && isEnterprise && domainSettings}
            {activeTab === 5 && isEnterprise && scriptSettings}
            {activeTab === 6 && analytics && <EditExitAttribution />}
            {activeTab === 7 && isEnterprise && <FooterSettingsPage />}
            {activeTab === 8 && flag.permalink_config && <EditPermalinkConfig />}
          </Box>
        </Container>

        <Dialog maxWidth="lg" open={domainDialog}>
          <DialogTitle>IMPORTANT: Copy and save the following</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>
                Copy and save the following information. You will need it to configure your domain.
                Information is not longer available after you close this window. </p>
              <p style={{ marginTop: '10px' }}>
                Domain takes up to 10mins to be active after DNS configurations.
              </p>
              <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>{t('Name')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('Value')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('Type')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customDomainResponse.map((row) => (
                      <TableRow
                        key={row.name}
                      >
                        <TableCell className={classes.tableCell}>{row.name}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.value}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{row.type}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDomainDialogClose} color="primary">
              I have copied the information
            </Button>
          </DialogActions>
        </Dialog>


      </Page>
    </Root>
  )
}

export default StoreSettings
