import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, Button, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
// import { stripHtml } from 'src/utils/helpers'
import { Ad, Post } from 'src/contexts/types'
import { InView } from 'react-intersection-observer'
import { SocialSearchOption, SocialSearchValue } from 'src/views/CreateStorefront/components/SocialPicker'


export type SocialSelectorProps<T> = {
  onRenderNext: () => void

  onSelect: (item: T) => void
  selected: T | null | undefined
  items: T[]
  renderItem: (item: T) => React.ReactNode

  error?: boolean

  nameRef?: React.MutableRefObject<HTMLElement | null>
  nameErrorString?: string
  inputRef?: React.MutableRefObject<HTMLElement | null>
  label?: string

  campaignName: string
  onCampaignNameChange: (name: string) => void
  campaignDescription: string
  onCampaignDescriptionChange: (name: string) => void
  defaultOpen?: boolean
  handleSearchChange: (search: string) => void
  searchString: string
  handleSearchTypeChange: (event: SelectChangeEvent<SocialSearchValue>) => void
  searchType: SocialSearchValue
  searchTypeOptions: SocialSearchOption[]
  keepOpen?: boolean
}

export const SocialSelector: FC<SocialSelectorProps<Ad | Post>> = ({
  onRenderNext,

  onSelect,
  selected,
  items,
  renderItem,
  error,
  nameRef,
  nameErrorString,
  inputRef,
  label,
  campaignName,
  onCampaignNameChange,
  campaignDescription,
  onCampaignDescriptionChange,
  defaultOpen = true,
  handleSearchChange,
  searchString,
  handleSearchTypeChange,
  searchType,
  searchTypeOptions,
  keepOpen,
}) => {
  const filterStringRef = useRef<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)
  const [calledNextItemsLength, setCalledNextItemsLength] = useState(-1)
  const skipDefaultOpen = useRef(false)
  const selectRef = useRef<HTMLInputElement>(null) // reference to the Select component
  const textRef = useRef<HTMLInputElement>(null)  // reference to the input field
  const isPopperOpen = keepOpen ? keepOpen : open

  useEffect(() => {
    if ((items.length > 0) && !selected && defaultOpen && !skipDefaultOpen.current) {
      // NOTE: delay needed because there is no batching of hook dependencies so
      //       'items' come in while 'selected' is undefined, then 'selected' comes in
      const timerId = setTimeout(() => setOpen(true), 200)
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [items, selected, defaultOpen])

  useEffect(() => {
    if (!open) {
      if (searchString !== '') {
        handleSearchChange('')
        skipDefaultOpen.current = true
      }
    }
  }, [handleSearchChange, open, searchString])

  useEffect(() => {
    if (open) setTimeout(() => filterStringRef?.current?.focus(), 0)
  }, [open, filterStringRef])

  useEffect(() => {
    if (items.length === 10) {
      setCalledNextItemsLength(-1)
    }
  }, [items.length])

  const close = () => {
    filterStringRef.current?.blur()
    setOpen(false)
  }

  const handleBlur = (event) => {
    if (event.relatedTarget && !keepOpen) {
      setTimeout(() => filterStringRef.current?.focus(), 0)
    } else {
      setOpen(false)
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        sx={{
          top: '4px',
          display: isPopperOpen ? 'none' : undefined,
        }}
        variant='outlined'
        onClick={() => setOpen(!open)}
      >
        {open ? 'Close' : '← Back to'} Gallery
      </Button>

      <Autocomplete
        freeSolo
        getOptionLabel={(option) => searchString}
        filterOptions={(options, state) => options}
        ListboxProps={{
          style: {
            textAlign: 'center',
            display: 'grid',
            gridGap: '24px',
            justifyContent: 'center',
            gridTemplateColumns: 'repeat(auto-fill, 150px)',
            padding: '0px !important',
            paddingBottom: '18px !important',
          },
        }}
        disablePortal={true}
        open={isPopperOpen}
        clearIcon={searchString ? undefined : <span />}
        options={items}
        noOptionsText={items.length === 0 && searchString !== '' ? 'Searching...' : 'No options'}

        inputValue={searchString}
        onInputChange={(e, value) => {
          if (e?.type !== 'change') return
          handleSearchChange(value)
        }}
        value={selected}
        onChange={(e, value) => {
          if (value && typeof value === 'object') {
            onSelect(value)
            close()
          }
        }}

        renderOption={(props, item) => {
          return (
            <li
              {...props}
              key={item.externalId}
              style={{
                display: 'unset',
                width: '150px',
                borderBottom: '1px solid lightgrey',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                backgroundColor: 'unset !important',
              }}
            >
              <InView
                as="span"
                onChange={(inView) => {
                  if (inView) {
                    // Check if the last item is in view
                    if ((items.length - 1) === props['data-option-index']) {
                      // get next content
                      setCalledNextItemsLength(items.length)
                      if (calledNextItemsLength !== items.length)
                        onRenderNext()
                    }
                  }
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pointerEvents: 'none',
                  height: '100%',
                  fontSize: 10,
                }}>
                {renderItem(item)}
              </InView>
            </li>
          )
        }}

        renderInput={(params) => {
          return (
            <Box>
              {
                !(!open && selected) ? '' :
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    transform: 'translateY(20px)',
                  }}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '12px',
                      justifyContent: 'space-between',
                      width: '160px',
                      fontSize: 10,
                    }}>
                      {renderItem(selected)}
                    </Box>

                  </Box>
              }
              <Box
                sx={{
                  opacity: isPopperOpen ? 1 : 0,
                }}
              >
                <TextField
                  ref={textRef}
                  fullWidth={true}
                  onBlur={handleBlur}
                  onFocus={() => {
                    if (!selected) {
                      setOpen(true)
                    }
                  }}

                  inputRef={(element) => {
                    filterStringRef.current = element
                    if (inputRef)
                      inputRef.current = element
                  }}

                  label={label}
                  error={error}

                  margin="dense" type="string" variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    value: searchString,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          ref={selectRef}
                          value={searchType}
                          onChange={handleSearchTypeChange}
                          onBlur={handleBlur}
                          onClick={() => {handleSearchChange(searchString)}}
                          variant="standard" // Removes border for a more integrated look
                          sx={{
                            minWidth: 70, // Adjust width to fit your dropdown
                            '& .MuiSelect-select': { padding: '0 8px' }, // Adjust padding for alignment
                          }}
                        >
                          {searchTypeOptions.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                    endAdornment: null,
                  }}
                  InputLabelProps={params.InputLabelProps}
                />
              </Box>
            </Box>
          )
        }}
      />

      {!isPopperOpen && selected &&
        <Box sx={{
          position: 'absolute',
          right: 0,
          top: 37,
          display: 'flex',
          flexDirection: 'column',
          width: 'calc(100% - 166px)',
          justifyContent: 'space-between',
        }}>
          <Box sx={{
            width: '100%',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <TextField
                sx={{
                  flex: 1,
                  mt: '20px',
                }}
                margin="dense" type="string" variant="outlined"
                label="Headline"

                inputRef={(element) => {
                  if (nameRef) nameRef.current = element
                }}
                error={!!nameErrorString}
                helperText={nameErrorString}
                value={campaignName}
                onChange={(e) => {
                  onCampaignNameChange(e.target.value)
                }}
              />
              <TextField
                multiline
                minRows={7}
                maxRows={10}
                sx={{
                  flex: 1,
                }}
                margin="dense" type="string" variant="outlined"
                label="Optional Subtitle"

                value={campaignDescription}
                onChange={(e) => onCampaignDescriptionChange(e.target.value)}
              />
            </Box>
          </Box>
          <Box>
          </Box>
        </Box>
      }

    </Box>
  )
}
